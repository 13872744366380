import React from 'react';

const LoaderSpinner = () => { 

  return (
    <div class="LoaderSpinnerDiv">
      <div class="loader">
    <div class="loader-inner ball-spin-fade-loader">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    </div>
  </div>
  );
};

export default LoaderSpinner;