import  React, { useState, useRef, useEffect  } from "react"; 
import { Link, useNavigate } from "react-router-dom";
import { Icons } from "../assest/icons";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Moment from 'moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; 
import { getBaseURL } from './baseUrl';

import { ToastContainer, toast } from "react-toastify"; 
import LeftSideLogin from "./leftsidelogin";
 
 
 
function SingupScreen() {  
  let navigate = useNavigate();  
  const [showCalendar, setShowCalendar] = useState(false);
  

  const [UserData, setUserData] = useState([]);

  const [ShowLoader, setShowLoader] = useState(false);

  const [UserId, setUserId] = useState('');
  const [firstStep, setFirstStep] = useState(true);
  const [twoStep, setTwoStep] = useState(false);
  const [threeStep, setThreeStep] = useState(false);
  const [finalStep, setFinalStep] = useState(false);

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [selectedToggle, setSelectedToggle] = useState(true);
  const [DOBTxt, setDOBTxt] = useState('');
  const [GenderTxt, setGenderTxt] = useState('');
  const [InsuranceIdTxt, setInsuranceIdTxt] = useState('');
  const [ClearBtn, setClearBtn] = useState(false);

  const [AgeTxt, setAgeTxt] = useState('');
  const [WeightTxt, setWeightTxt] = useState('');
  const [HeightTxt, setHeightTxt] = useState('');

  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false); 
  
  const [focused, setFocused] = React.useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const [isChecked, setIsChecked] = useState(false);

  const handleChange = () => {
    setIsChecked(!isChecked);
    setSelectedToggle(!selectedToggle); 
  };


  const handleClickPassword = (event) => { 
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const handlePasswordChange = (event) => {
    const { value } = event.target;
    setPassword(value);
    setErrors('');
};
const handleEmailChange = (event) => {
   // Regular expression for email validation
   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const { value } = event.target;
  setEmail(value);
    // Validate email
    if (!emailRegex.test(value)) {
      setErrors('Invalid email address');
    } else {
      setErrors('');
    } 

};
  function handleOnFocus() {
    setFocused(true);
  }

  function handleOnBlur() {
    setFocused(false);
  }
  const [errorMessage, setErrorMessage] = useState('');

  const nav = useNavigate();
  const routeChange = () =>{     
    localStorage.setItem('UserLoginStatus',  JSON.stringify(true) );
   
    nav("/dashboard", { state:UserData}); 
  }
  const routeFillUserInfo = () =>{     
    localStorage.setItem('UserLoginStatus',  JSON.stringify(true) );  
    nav("/filluserinfo", { state:UserData}); 
  }

  const handleResetFormStart= () => {
    setFullName('');
    setEmail('');
    setPhoneNumber('');
    setPassword(''); 
    setErrors('');
  };
  const handleResetForm1 = () => { 
    setPassword(''); 
    setErrors('');
  };
  const handleResetForm2 = () => { 
    setDOBTxt('');
    setGenderTxt('');
    setInsuranceIdTxt('');
    setErrors('');
  }; 
  const handleOptionChangeToogle = () => {
    setSelectedToggle(!selectedToggle); 
  };
  const handleClickFirst = () => {
    setFirstStep(false);
    setTwoStep(false);
    setThreeStep(true);
    setFinalStep(false);
  };  
 
  const handleClickThree = () => {
    setFirstStep(false);
    setTwoStep(false);
    setFinalStep(true);
    setThreeStep(false);
  };
  const handleClickFinal = () => {
    setFirstStep(false);
    setTwoStep(false);
    setFinalStep(true);
    setThreeStep(false);
  };
  const handlePhoneNumberChange = (event) => {
    // Remove non-numeric characters from the input value
    const value = event.target.value.replace(/\D/g, '');
    setPhoneNumber(value);
};

const handleFullNameChange = (event) => {
  // Remove non-text characters from the input value
  const value = event.target.value.replace(/[^a-zA-Z\s]/g, '');
  setFullName(value);
  setErrors('');
};

  const handleSubmit = (event) => {

        event.preventDefault();
    
        // Simple form validation
        let formErrors = {};
        if (!fullName.trim()) {
            formErrors.fullName = 'Please enter your full name';
        }
        if (!email.trim()) {
            formErrors.email = 'Please enter your email';
        }
        if (!isValidEmail(email)) {
          formErrors.email = 'Please enter a valid email address'; 
        }
        if (!phoneNumber.trim()) {
            formErrors.phoneNumber = 'Please enter your phone number';
        }
        if (!password.trim()) {
            formErrors.password = 'Please enter your password';
        }
        if (!isValidNewPassword(password)) {
          formErrors.password = 'Password must be at least 8 characters long'; 
        }
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }
        setShowLoader(true)
        // If validation passes, send data to API
        const data = { 
          fullName: fullName,
          email: email,
          phoneNumber: phoneNumber,
          password: password,
          dob: "",
          gender: "",
          insurance_ID: "",
          age: "",
          height: "",
          weight: ""
      };
    
      // Here, you would replace 'apiEndpoint' with your actual API endpoint
      fetch(getBaseURL+'/register', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
     
      .then(response => response.json())
      .then(data => {
        if( data.status == 'success'){
          toast.success("User profile registered successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          }); 
           
          localStorage.setItem('UserProfile', JSON.stringify(data));
          setUserData(data)
          setUserId(data.user_id)
           setShowLoader(false)
          handleResetForm1()
          routeFillUserInfo()
        }
        if( data.status == 'error'){
              toast.error(data.message, {
               position: toast.POSITION.TOP_RIGHT,
              });
              setShowLoader(false)
        }
          // Optionally, you can redirect the user or show a success message
      }) 
      .catch((err) => { 
        if (err.response && err.response.status === 400) { 
          setErrorMessage('Bad Request: Please check your input data.');
        } else {
          // Handle other types of errors
          setErrorMessage('An error occurred. Please try again later.');
        }
          console.log('Error:', err);
          // Optionally, you can display an error message to the user
      });
    };
    const isValidEmail = (email) => {
      // Regular expression to validate email format
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    };
    const isValidNewPassword = (password) => {
      return password.length >= 8;
    };
   
    const isValidDateFormat = (dateString) => {
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      return regex.test(dateString);
  };
  const onChangeCalendar = (date) => {
     const dateNew = new Date(date);     
    setDOBTxt(new Date(date));
    setShowCalendar(false);
    setErrors('');
};
  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
};
    const handleClearDate = () => {
      setClearBtn(false);
      // Clear the date by setting the dob state to an empty string
      setDOBTxt('');
  };
  const handleGenderChange = (e) => {
    setGenderTxt(e.target.value);
  };
  
//   const handleGenderChange = (event) => {
//     let { value } = event.target; 
//         // Allow delete action
//         if (value === '') {
//           setGenderTxt(''); 
//       } 
//       // Convert input to uppercase for comparison
//       value = value.toUpperCase();
//     // Allow only 'M' or 'F' characters
//     if ( value === 'M' || value === 'F') { 
//         setGenderTxt(value === 'M' ? 'Male' :  'Female');
//     }
// }; 
const handleSubmitPart2 = (event) => { 

      event.preventDefault(); 
      // Simple form validation
      let formErrors = {}; 
      if (!DOBTxt.length > 0) {
        formErrors.DOBTxt = 'Please select a date';
      }  
      if (!GenderTxt.trim()) {
          formErrors.GenderTxt = 'Please enter your gender';
      }
      if (!InsuranceIdTxt.trim()) {
          formErrors.InsuranceIdTxt = 'Please enter your Insurance ID';
      }   
      if (Object.keys(formErrors).length > 0) {
          setErrors(formErrors);
          return;
      }
      setShowLoader(true)
      // If validation passes, send data to API
      const dataReq = { 
        fullName: fullName,
        email: email,
        phoneNumber: phoneNumber, 
        dob: DOBTxt,
        gender:GenderTxt,
        insurance: InsuranceIdTxt,    
        user_id: UserId,
    }; 
  
    // Here, you would replace 'apiEndpoint' with your actual API endpoint
    fetch(getBaseURL+'/update_user', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataReq),
    })
     
    .then(response => response.json())
    .then(data => {
      if( data.status == 'success'){
        toast.success("User profile update successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        }); 
        localStorage.setItem('UserProfile', JSON.stringify(data));
        setUserData(data)
       setShowLoader(false)
        }
      if( data.status == 'error'){
        console.log('Success:', data.message);
          toast.error(data.message, {
             position: toast.POSITION.TOP_RIGHT,
            });
            setShowLoader(false)
      }
        // Optionally, you can redirect the user or show a success message
    })
    .catch((error) => {
        console.error('Error:', error);
        // Optionally, you can display an error message to the user
    });
  };
  const formatDate = (date) => {
     return date ? date.toString().slice(0, 10) : '';
};
const disablePastDates = ({ activeStartDate, date, view }) => {
  // Disable dates before the current date
  // return date >= new Date();
  return date.getMonth() > new Date().getMonth() || date.getFullYear() > new Date().getFullYear() || date >= new Date();
};

const InsuranceIdHandleKeyDown = (e) => {
 
};

const InsuranceIdHandleChange = (e) => {
    // Get the new value
    const value = e.target.value;
    let filteredValue = '';

  // Iterate over each character in the input value
  for (let i = 0; i < value.length; i++) {
    const char = value[i];
    // Allow alphanumeric characters and hyphens
    if ((char >= 'A' && char <= 'Z') || (char >= 'a' && char <= 'z') || (char >= '0' && char <= '9') || char === '-') {
      filteredValue += char;
    }
  }
    // Update the state with the filtered value
    setInsuranceIdTxt(filteredValue); 
};

  return (
    <div>
      <div className='container-fluid p-0 bgImageUrl'>
       
        <div className='container p-0 contentBody loginScreen'>

        <LeftSideLogin/>
          <div className="col-lg-5 d-inline-block position-relative">
            <div className="loginBoxDiv">
            <div className={"singUpFirstStep "+(firstStep ? "":"d-none")}>
                <h3 className="titleDespTxt">Sign up to AIGI Care</h3>
                <form onSubmit={handleSubmit} autoComplete="off">
               
                  <div className='inputGroupDiv'> 
                      <div className="inputTxtBox registerInputDiv">
                        <img src={Icons[0].userImg} className="logoImg" alt="" />
                          <input type="text" value={fullName} placeholder="Full Name" maxLength="100" onChange={handleFullNameChange} />  
                      
                      </div>
                      {errors.fullName && <span  className="errorTxt" >{errors.fullName}</span>}
                </div>
                  <div className='inputGroupDiv'> 
                      <div className="inputTxtBox registerInputDiv">
                        <img src={Icons[0].mailImg} className="logoImg" alt="" />
                          <input type="text" value={email} placeholder="Email id" autoComplete="off" onChange={handleEmailChange}/>  
                      
                      </div>
                      {errors.email && <span  className="errorTxt" >{errors.email}</span>}
                </div>
                  <div className="mb-4">
                    <PhoneInput 
                      autocomplete="off" 
                       defaultCountry="US"
                       country={"us"}
                      id="phone"
                      placeholder="Enter phone number"
                      value={phoneNumber}
                      onChange={setPhoneNumber} 
                      international={false}
                      error={!isValid && phoneNumber.trim().length > 0}
                      onBlur={() => setIsValid(!!phoneNumber.trim())}
                      inputProps={{
                        name: 'phone',
                        required: true,
                        autoFocus: true, 
                        autoComplete: 'off',
                      }}  
                    />
                    {errors.phoneNumber && <span  className="errorTxt" >{errors.phoneNumber}</span>}
                  </div>                  
                  
                  <div className='inputGroupDiv'> 
                      <div className="inputTxtBox registerInputDiv">
                            <img src={Icons[0].lockedImg} className="logoImg" alt="" />
                              <input type={passwordType}  value={password} placeholder="Password" autocomplete="new-password" onChange={handlePasswordChange}/>   
                              <label className='eyePwdIcon' onClick={handleClickPassword}>  
                                <img src={passwordType === "password" ? ( Icons[0].closeEye  ) : ( Icons[0].opneEye  )} className="logoImg" alt="" />
                              </label>                
                          </div>
                          {errors.password && <span  className="errorTxt" >{errors.password}</span>}
                    </div>
                  
                  <div className="optDivBox mt-4">
                  <div className="switch-container d-flex">
                        <label>
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleChange}
                            className="switch"
                          />
                          <div className="switch-slider">
                            <div className="switch-knob"></div>
                          </div>
                        </label>
                        <span style={{ marginLeft: "5px" }}> I agree to the Terms of Use & Privacy Policy.</span>
                      </div>

                    {/* <span className="switchToogle "><input type="checkbox" id="switch"   onChange={handleOptionChangeToogle} /><label for="switch" >Toggle</label>
                     I agree to the Terms of Use & Privacy Policy.</span>  */}
                    
                  </div>
                  {ShowLoader ? (<>
                      <button  className="signInBtn" > Loading
                      <div className="spinner-border text-light  " role="status">
                       <span className="visually-hidden">Loading...</span>
                    </div> 
                    </button>
                    </>):(<>
                      <button className="signInBtn " type="submit" disabled={selectedToggle}>Continue</button>
                    </>)}
                 
                 <div className="signupDiv">
                  Already have an account <Link to="/">Sign in</Link>
                  </div>
                </form>
             </div>

           

              <div className={"singUpThreeStep "+(threeStep ? "":"d-none")}>
              <h3 className="titleDespTxt">Please fill details to continue</h3>
                <form  onSubmit={handleSubmitPart2} autoComplete="off">
                  <div className={"inputTxtBox registerInputDiv "+ (fullName.length > 0 ? 'disabled' : '')}>
                    <img src={Icons[0].userImg} className="logoImg" alt="" />
                    <input type="text" value={fullName} placeholder="Full Name"  readOnly />
                  </div>
                  <div className={"inputTxtBox registerInputDiv "+ (email.length > 0 ? 'disabled' : '')}>
                    <img src={Icons[0].mailImg} className="logoImg" alt="" />
                    <input type="text" value={email} placeholder="Email id" readOnly  /> 
                  </div>
                  <div className={"registerInputDiv "+ (email.length > 0 ? 'disabled' : '')}>
                    <PhoneInput
                      defaultCountry="US"
                      country={"us"}
                     id="phone"
                     placeholder="Enter phone number"
                     value={phoneNumber}
                     onChange={setPhoneNumber} 
                     international={false}
                     error={!isValid && phoneNumber.trim().length > 0}
                     onBlur={() => setIsValid(!!phoneNumber.trim())}
                     inputProps={{
                       name: 'phone',
                       required: true,
                       autoFocus: true
                     }} 
                    />
                  </div> 
                      <div className="inputTxtBox  registerInputDiv">
                        <img src={Icons[0].dateIcon} className="logoImg" alt="" />
                        <input type="text"  maxLength="11"  value={formatDate(DOBTxt)} placeholder="Date of Birth (YYYY-MM-DD)"  
                         
                            /> 
                          <button className="toggleCalendarBtn"  onClick={toggleCalendar}> <img src={Icons[0].calendarIcon} className="logoImg" alt="" /></button>
                          {showCalendar && (
                              <Calendar 
                                className= 'inputCaledar' 
                                onChange={onChangeCalendar}
                                value={DOBTxt}
                                maxDate={new Date()} 
                                tileDisabled={disablePastDates} 
                              />
                          )}
     </div>
                  {errors.DOBTxt && <span  className="errorTxt" >{errors.DOBTxt}</span>} 
                  
                      <div className="inputTxtBox  registerInputDiv d-flex">
                        <img src={Icons[0].GenderImg} className="logoImg" alt="" />
                        <div className="gender-selector">
                            <label>
                              <input
                                type="radio"
                                value="Male"
                                checked={GenderTxt === 'Male'}
                                onChange={handleGenderChange}
                              />
                              Male
                            </label>
                            <label>
                              <input
                                type="radio"
                                value="Female"
                                checked={GenderTxt === 'Female'}
                                onChange={handleGenderChange}
                              />
                              Female
                            </label>
                            <label>
                              <input
                                type="radio"
                                value="Other"
                                checked={GenderTxt === 'Other'}
                                onChange={handleGenderChange}
                              />
                              Other
                            </label>
                          </div>
                           {/* <input type="text" value={GenderTxt} placeholder="Press M or F for gender" onChange={handleGenderChange} maxLength="1"   
                        onKeyUp={(e) => { if (e.key === 'Backspace') {     setGenderTxt('');  } }}/>  */}
                  </div>
                  {errors.GenderTxt && <span  className="errorTxt" >{errors.GenderTxt}</span>} 
                  <div className="inputTxtBox registerInputDiv ">
                    <img src={Icons[0].InsuranceIDIcon} className="logoImg" alt="" />
                
                   <input type="text"
                    value={InsuranceIdTxt}  
                    // onChange={(e) => setInsuranceIdTxt(e.target.value)}  
                    placeholder="Insurance ID"
                    maxLength="40"
                    onKeyDown={InsuranceIdHandleKeyDown}
                    onChange={InsuranceIdHandleChange}
                     />
                  </div>
                  {errors.InsuranceIdTxt && <span  className="errorTxt" >{errors.InsuranceIdTxt}</span>} 
                   
                  {ShowLoader ? (<>
                      <button  className="signInBtn" > Loading
                      <div className="spinner-border text-light  " role="status">
                       <span className="visually-hidden">Loading...</span>
                    </div> 
                    </button>
                    </>):(<>
                      <button className="signInBtn registerBtn mb-0"   >Finish</button>
                    </>)}
              </form>
              </div>
              <div className={"singUpFinalStep  "+(finalStep ? "":"d-none")}>
                    <span className="emailVerificationDiv">
                    <img src={Icons[0].checkedIcon} className="logoImg" alt="" />
                    <h3>Success!</h3>
                    <p>Congratulations! Your account has beed created successfully. Continue to the dashboard.</p>
                    <button className="signInBtn mb-0"   onClick={routeChange} >Continue</button>
               
                    </span>
              </div>
            </div>

           
          </div>
          
        </div>
      
      </div>
    </div>
  );
}

export default SingupScreen;