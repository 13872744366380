import  React, { useState, useRef, useEffect  } from "react"; 
import { Link, useNavigate, useSearchParams } from "react-router-dom"; 
import { Icons } from "../assest/icons";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Moment from 'moment';
import { getBaseURL } from './baseUrl';

import { ToastContainer, toast } from "react-toastify"; 
import LeftSideLogin from "./leftsidelogin";
 
 
function ResetPwd() {    
  let navigate = useNavigate(); 
  const [searchParams, setSearchParams] = useState('');
  
  const [ShowLoader, setShowLoader] = useState(false); 
  const [UserId, setUserId] = useState('');
  const [firstStep, setFirstStep] = useState(true);
  const [twoStep, setTwoStep] = useState(false); 
  const [finalStep, setFinalStep] = useState(false);
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [passwordType, setPasswordType] = useState("password");
  const [NewPwd, setNewPwd] = useState('');
  const [ConfirmPwd, setConfirmPwd] = useState('');

  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false); 
  const handleLoginPage = () => { 
    navigate("/");
  }
  useEffect(() => {
    // Function to get URL parameters
    const getURLParameter = (name) => {
        const params = new URLSearchParams(window.location.search);
        return params.get(name);
    };

    // Get the value of the 'token' parameter
    const token = getURLParameter('token');
    setSearchParams(token); 
}, []); 
const handleResetFormStart= () => { 
  setNewPwd('');  
  setConfirmPwd(''); 
  setErrors('');
};
const handleClickShowPassword = (event) => { 
  if (passwordType === "password") {
    setPasswordType("text");
    return;
  }
  setPasswordType("password");
}; 
const handleClickShowNewPassword = (event) => { 
  if (newPasswordType === "password") {
    setNewPasswordType("text");
    return;
  }
  setNewPasswordType("password");
};
const handleNewPasswordChange = (event) => {
  const { value } = event.target;
  setNewPwd(value);
  setErrors('');
};

const handleConfirmPasswordChange = (event) => {
  const { value } = event.target;
  setConfirmPwd(value);
  setErrors('');
};
 
 
  const [errorMessage, setErrorMessage] = useState('');

  
 
 
  const handleSubmit = (event) => { 
    
        event.preventDefault();
  
      
    
        // Simple form validation
        let formErrors = {}; 
         if (!NewPwd.trim()) {
          formErrors.NewPwd =  'Please enter your New password';
          }
          if (!ConfirmPwd.trim()) {
              formErrors.ConfirmPwd = 'Please enter your Confirmed password';
          }  
          if (!isValidNewPassword(NewPwd)) {
            formErrors.NewPwd = 'New password must be at least 8 characters long'; 
          }
          if (NewPwd !== ConfirmPwd ) {
            formErrors.ConfirmPwd = 'New password and confirm password do not match';   
          }
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }
        setShowLoader(true)
        // If validation passes, send data to API
        const data = {  
          token: searchParams, 
          new_password : NewPwd,
          confirm_password : ConfirmPwd 
      };
    
      // Here, you would replace 'apiEndpoint' with your actual API endpoint
      fetch(getBaseURL+'/reset_password', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
     
      .then(response => response.json())
      .then(data => {
        if( data.status == 'success'){
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });  
          setShowLoader(false)       
          setFirstStep(false); 
          setFinalStep(true);      
          handleResetFormStart() 
        }
        if( data.status == 'error'){ 
            toast.error(data.message, {
               position: toast.POSITION.TOP_RIGHT,
              });
              setShowLoader(false)
        }
          // Optionally, you can redirect the user or show a success message
      }) 
      .catch((error) => {
        console.error('Error:', error);
        // Optionally, you can display an error message to the user
    });
  };
    const isValidNewPassword = (password) => {
      return password.length >= 8;
    };
 
    
 
  return (
    <div>
      <div className='container-fluid p-0 bgImageUrl'>
       
        <div className='container p-0 contentBody loginScreen'>

        <LeftSideLogin />
          <div className="col-lg-5 d-inline-block position-relative">
            <div className="loginBoxDiv">
            <div className={"singUpFirstStep "+(firstStep ? "":"d-none")}>
                <h3 className="titleDespTxt">Enter a New Password</h3> 
                <form onSubmit={handleSubmit} autoComplete="off">
               
                 
                <div className='inputGroupDiv'>
                        <label className='labelTxt'> New Password</label>
                      <div className="inputTxtBox registerInputDiv"> 
                      <img src={Icons[0].lockedImg} className="logoImg" alt="" />          
                        <input type={newPasswordType}  value={NewPwd}    
                        onChange={handleNewPasswordChange} /> 
                        <label className='eyePwdIcon' onClick={handleClickShowNewPassword}>  
                          <img src={newPasswordType === "password" ? ( Icons[0].closeEye  ) : ( Icons[0].opneEye  )} className="logoImg" alt="" />
                        </label> 
                      </div> 
                      {errors.NewPwd && <span  className="errorTxt" >{errors.NewPwd}</span>}
                  </div>
                  <div className='inputGroupDiv'>
                        <label className='labelTxt'>Confirm Password</label>
                      <div className="inputTxtBox registerInputDiv">
                        <img src={Icons[0].lockedImg} className="logoImg" alt="" />
                        <input type={passwordType}  value={ConfirmPwd}    
                        onChange={handleConfirmPasswordChange}/>   
                        <label className='eyePwdIcon' onClick={handleClickShowPassword}>  
                          <img src={passwordType === "password" ? ( Icons[0].closeEye  ) : ( Icons[0].opneEye  )} className="logoImg" alt="" />
                        </label>    
                      </div>
                      {errors.ConfirmPwd && <span  className="errorTxt" >{errors.ConfirmPwd}</span>}
                   </div>
                            
                 
                  {ShowLoader ? (<>
                      <button  className="signInBtn" > Loading
                      <div className="spinner-border text-light  " role="status">
                       <span className="visually-hidden">Loading...</span>
                    </div> 
                    </button>
                    </>):(<>
                      <button className="signInBtn " type="submit" >Reset Password</button>
                    </>)}
                  
                  <div className="signupDiv"> 
                   <Link to="/">Back to login</Link>
                  </div>
                </form>
             </div> 

              
              <div className={"singUpFinalStep  "+(finalStep ? "":"d-none")}>
                    <span className="emailVerificationDiv w-100">
                    <img src={Icons[0].checkedIcon} className="logoImg" alt="" />
                    <h3>Reset Password</h3>
                    <p>Your password has been successfully reset.</p>
                    <button className="signInBtn mb-0" onClick={handleLoginPage}  >Login</button>
                     </span>
              </div>
            </div>

           
          </div>
          
        </div>
      
      </div>
    </div>
  );
}

export default ResetPwd;