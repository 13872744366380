import  React, { useState, useRef, useEffect  } from "react"; 
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Icons } from "../assest/icons";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Moment from 'react-moment';
import moment from 'moment'; 
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { getBaseURL } from './baseUrl';



import { ToastContainer, toast } from "react-toastify"; 
import LeftSideLogin from "./leftsidelogin";
 
 
function SingupFillUserInfo() {  
  const location = useLocation(); 
  const [data, setdata] = useState(location.state); 
  const userDataInfo =  JSON.parse(localStorage.getItem('UserProfile'))
 
  let navigate = useNavigate();  
  const [showCalendar, setShowCalendar] = useState(false);  

  const [UserData, setUserData] = useState([]);

  const [ShowLoader, setShowLoader] = useState(false);

  const [UserId, setUserId] = useState(userDataInfo.user_id);
  const [firstStep, setFirstStep] = useState(false);
  const [twoStep, setTwoStep] = useState(false);
  const [threeStep, setThreeStep] = useState(true);
  const [finalStep, setFinalStep] = useState(false);

  const [fullName, setFullName] = useState(userDataInfo.fullName);
  const [email, setEmail] = useState(userDataInfo.email ? userDataInfo.email : '');
  const [phoneNumber, setPhoneNumber] = useState(userDataInfo.phoneNumber ?  userDataInfo.phoneNumber : '');
  const [password, setPassword] = useState('');
  const [selectedToggle, setSelectedToggle] = useState(true);
  const [DOBTxt, setDOBTxt] = useState('');
  const [GenderTxt, setGenderTxt] = useState('');
  const [InsuranceIdTxt, setInsuranceIdTxt] = useState('');
  const [ClearBtn, setClearBtn] = useState(false);

  const [AgeTxt, setAgeTxt] = useState('');
  const [WeightTxt, setWeightTxt] = useState('');
  const [HeightTxt, setHeightTxt] = useState('');

  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false); 
  const [CheckLogin, setCheckLogin] = useState(userDataInfo.login_from ?  userDataInfo.login_from : '');
  
  const [focused, setFocused] = React.useState(false); 
 
  function handleOnFocus() {
    setFocused(true);
  }

  function handleOnBlur() {
    setFocused(false);
  }
  const [errorMessage, setErrorMessage] = useState('');

  const nav = useNavigate();
  const routeChange = () =>{     
    localStorage.setItem('UserLoginStatus',  JSON.stringify(true) );
 
    nav("/dashboard", { state:UserData}); 
  }
  const handleResetFormStart= () => {
    setFullName('');
    setEmail('');
    setPhoneNumber('');
    setPassword(''); 
    setErrors('');
  };
  const handleResetForm1 = () => { 
    setPassword(''); 
    setErrors('');
  };
  const handleResetForm2 = () => { 
    setDOBTxt('');
    setGenderTxt('');
    setInsuranceIdTxt('');
    setErrors('');
  }; 
  const handleOptionChangeToogle = () => {
    setSelectedToggle(!selectedToggle); 
  };
  const handleClickFirst = () => {
    setFirstStep(false);
    setTwoStep(false);
    setThreeStep(true);
    setFinalStep(false);
  };  
 
  const handleClickThree = () => {
    setFirstStep(false);
    setTwoStep(false);
    setFinalStep(true);
    setThreeStep(false);
  };
  const handleClickFinal = () => {
    setFirstStep(false);
    setTwoStep(false);
    setFinalStep(true);
    setThreeStep(false);
  };
  const handlePhoneNumberChange = (event) => {
    // Remove non-numeric characters from the input value
    const value = event.target.value.replace(/\D/g, '');
    setPhoneNumber(value);
};

const handleFullNameChange = (event) => {
  // Remove non-text characters from the input value
  const value = event.target.value.replace(/[^a-zA-Z\s]/g, '');
  setFullName(value);
  setErrors('');
};

 
    const isValidEmail = (email) => {
      // Regular expression to validate email format
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    };
    const isValidNewPassword = (password) => {
      return password.length >= 8;
    };
   
    const isValidDateFormat = (dateString) => {
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      return regex.test(dateString);
  };
  const onChangeCalendar = (date) => {     
    setDOBTxt(formatDate(date));
    setShowCalendar(false);
    setErrors('');
};
  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
};
    const handleClearDate = () => {
      setClearBtn(false);
      // Clear the date by setting the dob state to an empty string
      setDOBTxt('');
  };
 
  const handleGenderChange = (e) => {
    setGenderTxt(e.target.value); 
  };
//   const handleGenderChange = (event) => {
//     let { value } = event.target; 
//         // Allow delete action
//         if (value === '') {
//           setGenderTxt(''); 
//       } 
//       // Convert input to uppercase for comparison
//       value = value.toUpperCase();
//     // Allow only 'M' or 'F' characters
//     if ( value === 'M' || value === 'F') { 
//         setGenderTxt(value === 'M' ? 'Male' :  'Female');
//     }
// }; 
const handleSubmitPart2 = (event) => { 

      event.preventDefault(); 
      // Simple form validation
      let formErrors = {}; 
      if (!DOBTxt.length > 0) {
        formErrors.DOBTxt = 'Please select a date';
      }  
      if (!GenderTxt.trim()) {
          formErrors.GenderTxt = 'Please enter your sex';
      }
      if (!InsuranceIdTxt.trim()) {
          formErrors.InsuranceIdTxt = 'Please enter your Insurance ID';
      }   
      if (Object.keys(formErrors).length > 0) {
          setErrors(formErrors);
          return;
      }
      setShowLoader(true)
      // If validation passes, send data to API
      const dataReq = { 
        fullName: fullName,
        email: email,
        phoneNumber: phoneNumber, 
        dob: DOBTxt,
        gender:GenderTxt,
        insurance: InsuranceIdTxt,    
        user_id: UserId,
    };
    
  
    // Here, you would replace 'apiEndpoint' with your actual API endpoint
    fetch(getBaseURL+'/update_user', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataReq),
    })
     
    .then(response => response.json())
    .then(data => {
      if( data.status == 'success'){
        toast.success("User profile update successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        }); 
        localStorage.setItem('UserProfile', JSON.stringify(data));
        setUserData(data)
      
        setShowLoader(false)
        handleClickThree()
        handleResetForm2()
      }
      if( data.status == 'error'){ 
          toast.error(data.message, {
             position: toast.POSITION.TOP_RIGHT,
            });
            setShowLoader(false)
      }
        // Optionally, you can redirect the user or show a success message
    })
    .catch((error) => {
        console.error('Error:', error);
        // Optionally, you can display an error message to the user
    });
  };
  const formatDate = (date) => { 
    return date ? moment(date).format("YYYY-MM-DD") : '';
};
const disablePastDates = ({ activeStartDate, date, view }) => {
  // Disable dates before the current date
return date >= new Date();
 // return date.getMonth() > new Date().getMonth() || date.getFullYear() > new Date().getFullYear() || date >= new Date();
};


const InsuranceIdHandleChange = (e) => {
  // Get the new value
  const value = e.target.value;    

  // Regex to allow only alphanumeric characters, hyphens, and spaces
  const regex = /^[\w\s-]*$/;

  if (value === '' || regex.test(value)) {
    let newValue = value.replace(/\s+/g, ' '); 
    setInsuranceIdTxt(newValue);
  } 

};


  return (
    <div>
      <div className='container-fluid p-0 bgImageUrl'>
       
        <div className='container p-0 contentBody loginScreen'>

       <LeftSideLogin/>
          <div className="col-lg-5 d-inline-block position-relative">
            <div className="loginBoxDiv">
            {
              threeStep ? (<>
               <div className={"singUpThreeStep " }>
              <h3 className="titleDespTxt">Please fill details to continue</h3>
                <form  onSubmit={handleSubmitPart2} autoComplete="off">
                  <div className={"inputTxtBox registerInputDiv "+ (fullName.length > 0 ? 'disabled' : '')}>
                    <img src={Icons[0].userImg} className="logoImg" alt="" />
                    <input type="text" value={fullName} placeholder="Full Name"  readOnly />
                  </div>
                  <div className={"inputTxtBox registerInputDiv "+ (email.length > 0 ? 'disabled' : '')}>
                    <img src={Icons[0].mailImg} className="logoImg" alt="" />
                    <input type="text" value={email} placeholder="Email id" readOnly  /> 
                  </div>
                  <div className={"registerInputDiv "+ (CheckLogin != 'google'? (phoneNumber.length > 0 ? 'disabled' : '') :('')) }>
                    <PhoneInput
                      defaultCountry="US"
                      country={"us"}
                     id="phone"
                     placeholder="Enter phone number"
                     value={phoneNumber}
                     onChange={setPhoneNumber} 
                     international={false}
                     error={!isValid && phoneNumber.trim().length > 0}
                     onBlur={() => setIsValid(!!phoneNumber.trim())}
                     inputProps={{
                       name: 'phone',
                       required: true,
                       autoFocus: true
                     }} 
                    />
                  </div> 
                      <div className="inputTxtBox  registerInputDiv">
                        <img src={Icons[0].dateIcon} className="logoImg" alt="" />
                        <input type="text"    value={DOBTxt} placeholder="Date of Birth (YYYY-MM-DD)" readOnly /> 
                        <button className="toggleCalendarBtn"  onClick={toggleCalendar}> <img src={Icons[0].calendarIcon} className="logoImg" alt="" /></button>
                          {showCalendar && (
                              <Calendar 
                                className= 'inputCaledar' 
                                onChange={onChangeCalendar}
                                value={DOBTxt}
                                maxDate={new Date()} 
                                tileDisabled={disablePastDates}
                                />
                          )}
                   </div>
                  {errors.DOBTxt && <span  className="errorTxt" >{errors.DOBTxt}</span>} 
                      <div className="inputTxtBox  registerInputDiv d-flex justify-content-start">
                        <img src={Icons[0].GenderImg} className="logoImg" alt="" />
                        {/* <input type="text" value={GenderTxt} placeholder="Sex" onChange={handleGenderChange} maxLength="1"   
                        onKeyUp={(e) => { if (e.key === 'Backspace') { setGenderTxt('');  } }}/>  */}
                               <div className="gender-selector  d-flex justify-content-between ">
                            <label className="d-flex align-items-center">
                              <input
                                type="radio"
                                value="Male"
                                checked={GenderTxt === 'Male'}
                                onChange={handleGenderChange}
                              />
                              Male
                            </label>
                            <label  className="d-flex align-items-center">
                              <input
                                type="radio"
                                value="Female"
                                checked={GenderTxt === 'Female'}
                                onChange={handleGenderChange}
                              />
                              Female
                            </label>
                            {/* <label>
                              <input
                                type="radio"
                                value="Other"
                                checked={GenderTxt === 'Other'}
                                onChange={handleGenderChange}
                              />
                              Other
                            </label> */}
                          </div>
                  </div>
                  {errors.GenderTxt && <span  className="errorTxt" >{errors.GenderTxt}</span>} 


                  <div className="inputTxtBox registerInputDiv">
                    <img src={Icons[0].InsuranceIDIcon} className="logoImg" alt="" />
                    <input type="text" 
                    value={InsuranceIdTxt} 
                    placeholder="Insurance ID" 
                    maxLength="20" 
                    // onChange={(e) => setInsuranceIdTxt(e.target.value)}  
                    onChange={InsuranceIdHandleChange}
                     /> 
                  </div>
                  {errors.InsuranceIdTxt && <span  className="errorTxt" >{errors.InsuranceIdTxt}</span>} 
                   
                  {ShowLoader ? (<>
                      <button  className="signInBtn" > Loading
                      <div className="spinner-border text-light  " role="status">
                       <span className="visually-hidden">Loading...</span>
                    </div> 
                    </button>
                    </>):(<>
                      <button className="signInBtn registerBtn mb-0"   >Finish</button>
                    </>)}
              </form>
              </div>
              </>):(<>
                <div className={"singUpFinalStep  " }>
                    <span className="emailVerificationDiv">
                    <img src={Icons[0].checkedIcon} className="logoImg" alt="" />
                    <h3>Success!</h3>
                    <p>Congratulations! Your account has beed created successfully. Continue to the dashboard.</p>
                    <button className="signInBtn mb-0"   onClick={routeChange} >Continue</button>
               
                    </span>
              </div>
              </>)
            }
           
              
            </div>

           
          </div>
          
        </div>
      
      </div>
    </div>
  );
}

export default SingupFillUserInfo;