import React, { createContext, useState } from 'react';

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const clearSearchQuery = () => {
    setSearchQuery('');
  };
  return (
    <SearchContext.Provider value={{ searchQuery, setSearchQuery, clearSearchQuery  }}>
      {children}
    </SearchContext.Provider>
  );
};