import  React, { useState, useRef, useEffect, useContext  } from "react"; 
import { Link, useLocation, useNavigate } from "react-router-dom"; 
import axios from 'axios';
import { Icons } from "../../assest/icons";
import Header from './header';
import SideBarMenu from './sideBarMenu';
import moment from 'moment'; 
import LoaderSpinner from '../loaderSpinner';
import {getBaseURL} from '../baseUrl';
import { SearchContext } from './SearchContext'; 
 
function ViewPatientProfile() {  
    let navigate = useNavigate();
    const routeChange = () => { 
      navigate('/admin/dashboard', {
         state: {backCurrentPage: backCurrentPage, searchTxt: searchTxtCheck }
      
      }); 
    }
    const location = useLocation(); 
    const { id, currentPageNo, searchString } = location.state;
  
    const [ShowLoaderPage, setShowLoaderPage] = useState(false);
    const [userid, setuserid] = useState(id); 
    const [adminId, setAdminId] = useState(JSON.parse(localStorage.getItem('UserIdAdmin'))); 

    const [searchTxtCheck, setSearchTxtCheck] = useState(searchString); 

    const [backCurrentPage, setcurrentPageNo] = useState(currentPageNo);
    const [userProfileData, setUserProfileData] = useState([]);
    const [UserHistoryData, setUserHistoryData] = useState([]);
    const [UserHistoryNumber, setUserHistoryNumber] = useState('');
    const [ChatHistory, setChatHistory] = useState([]);
    const [RecordData, setRecordData] = useState([]);  
    const [BackReload, setBackReload] = useState(false);   
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userProfile, setUserProfile] = useState('');  
  const [specialty, setSpecialty] = useState('');
  const [selectedToggle, setSelectedToggle] = useState(true);
  const [DOBTxt, setDOBTxt] = useState(''); 
  const [InsuranceIdTxt, setInsuranceIdTxt] = useState('');
  const [GenderTxt, setGenderTxt] = useState('');
  const [originalProfile, setOriginalProfile] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');   
  var years = moment().diff(DOBTxt);
  const ageCal = moment().diff(DOBTxt, 'years'); 
  const [BMITxt, setBMITxt] = useState('');   

  const [AgeTxt, setAgeTxt] = useState(ageCal);  
  const [WeightTxt, setWeightTxt] = useState('');
  const [HeightTxt, setHeightTxt] = useState('');
  const [ShowLoader, setShowLoader] = useState(false); 

  const [UserId, setUserId] = useState(userid);  
  const [showCalendar, setShowCalendar] = useState(false);  
  const [isValid, setIsValid] = useState(false);
  const [isSession, setIsSession] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [showSummay, setShowSummary] = useState(false);
  const totalPages = Math.ceil(UserHistoryData.length / recordsPerPage);
  const totalPageGroups = Math.ceil(totalPages / 3);
  const currentPageGroup = Math.ceil(currentPage / 3);

  const [illnessesList, setIllnessesList] = useState([]);
  const [selectedIllness, setSelectedIllness] = useState(null);
  const [answerLoader, setAnswerLoader] = useState(false);
  const [EmergencyQuest, setEmergencyQuest] = useState(false);
   
  const [activeTab, setActiveTab] = useState(0);
  const { searchQuery } = useContext(SearchContext);
 
  const [MainPointsDiscussed, setMainPointsDiscussed] = useState('');
  const [PlanOnCareData, setPlanOnCareData] = useState('');
 
  const [SessionIDCheck, setSessionIDCheck] = useState('');
  const [reportData, setReportData] = useState(null);
 
  useEffect(() => {
    if (searchQuery) {
      navigate('/admin/dashboard');
    }
  }, [searchQuery, navigate]);

   // Fetch illnesses
   const illnessesArray = async () => {
    const response = await axios.get(getBaseURL+'/illnesses');  
    setIllnessesList(response.data.illnesses); 
  };

  const handleRecordsPerPageChange = (event) => {
    setRecordsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNextGroup = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevGroup = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFindRecord = (id) => {
    
  };
  const handleShowSummary = async (id) => {  
    setShowLoaderPage(true)
    const foundRecord = UserHistoryData.find((item) => item.created_on === id); 
    if (foundRecord) { 
      const filteredConversation = foundRecord.conversation.filter(convo => Object.keys(convo).length !== 0); 
      setRecordData(foundRecord);
      setSelectedIllness(foundRecord.illness) 
      setChatHistory(filteredConversation)  
      setPlanOnCareData(foundRecord.plan_of_care)
      setSessionIDCheck(foundRecord.session_id) 
      // Check if the answer to the specific question is "yes"
      const emergencyQuestion = filteredConversation.find(convo => 
        convo.question === "Do you need emergency care? (YES/NO)" && convo.answer.toLowerCase() === "yes"
      );

      if (emergencyQuestion) {
        // alert("Emergency care is needed!");
        setEmergencyQuest(true)
      } 
      setChatHistory([]);
      setShowSummary(true)   
      if(foundRecord.session){ 
        adminCheckedSession(foundRecord.user_id, foundRecord.session_id)
      }
      
    } else {
      setRecordData(null); 
    }
 
   try {
    const response = await axios.get(getBaseURL+'/illnesses'); 
    if (response.status === 200) {
      setIllnessesList(response.data.illnesses);        
      setShowLoaderPage(false)
    }

    } catch (error) {
     
   } finally {
      
    }
    try { 
      const url = `${getBaseURL}/download/${foundRecord.reports_id}`;
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
        
      if (response.data && response.data.status === 'success') {
        setReportData(response.data.content);
      }
      } catch (error) {
        //console.error('Error posting data:', error);
     } finally {
        
      }
  
  };
  
  const adminCheckedSession = async (patientId, sessionId) => {
    try {
      const response = await axios.post(`${getBaseURL}/admin_log`, {
        
          patient_id: patientId,
          admin_id: adminId,
          session_id: sessionId,  
      
      }); 
  
    
      if( response.data.status == 'success'){ 
        setBackReload(true)
      }

    } catch (error) {
      console.error('Error fetching data:', error);
      setShowLoader(false);
    }
  }

  const handleShowChat = (id) => {
    const filteredConversation = RecordData.conversation.filter(convo => Object.keys(convo).length !== 0);
 
    setActiveTab(id);
    setAnswerLoader(true)
    setTimeout(() =>  { 
      setChatHistory(filteredConversation); 
      setAnswerLoader(false) 
    }, 1000);
  }
  const handleHideSummary = () => { 
    setActiveTab(0);
    setRecordData([])
    setSelectedIllness() 
    if(BackReload){
      window.location.reload(); 
    }
    setShowSummary(false)
    setAnswerLoader(false) 
    setEmergencyQuest(false)
   };
   
  const handleShowDashboard = () => {
    setShowSummary(false)
    routeChange()
   };
  const renderTableBody = () => {
    const start = (currentPage - 1) * recordsPerPage;
    const end = start + recordsPerPage;
    const pageData = UserHistoryData?.slice(start, end);

    return pageData.map((UserHistoryData, index) => (
      <tr key={index}  onClick={() => handleShowSummary(UserHistoryData.created_on)} style={{cursor:'pointer'}} className={UserHistoryData.session === 'true' ? 'active' : ''}>
        <td><span className="d-flex align-items-center"><img src={Icons[0].admin_listIcon} style={{marginRight:'10px'}}/>{UserHistoryData.illness}</span></td>
        <td style={{textAlign:'center'}}> {moment(UserHistoryData.created_on).format('MMM DD, YYYY')}</td>
        <td style={{textAlign:'right'}}> <button className="viewBtnClass">View</button></td> 
      </tr>
    ));
    
     
  };

  const renderPagination = () => {
    let pages = [];
    const startPage = (currentPageGroup - 1) * 3 + 1;
    const endPage = Math.min(startPage + 2, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          className={`page-button ${i === currentPage ? 'active' : ''}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    return (
      <div className="pagination">
        <button
          onClick={handlePrevGroup}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {pages}
        <button
          onClick={handleNextGroup}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      
      </div>
    );
  };


   useEffect(() => {
    const fetchRecords = async () => {
    
      setShowLoaderPage(true)
 
      try {
        // setShowLoaderPage(true)
        // Make a GET request to fetch records from the API
        const response = await axios.get(getBaseURL+'/get_user_sessions/'+userid, {
          headers: {
            'Content-Type': 'application/json, text/html', // Specify the Content-Type header
          },
        });
        setShowLoaderPage(true)
        // Set user profile data
      setUserProfileData(response.data.user_details);

      // Sort history data by created_on date in descending order
        const sortedHistory = [...response.data.history];  
        setUserHistoryData(sortedHistory);
        setUserHistoryNumber(response.data.history.length) ; 
   
        setBMITxt(response.data.bmi ? response.data.bmi : 0);
        setFullName(response.data.user_details.fullName);
        setEmail(response.data.user_details.email);
        setDOBTxt(response.data.user_details.dob);
        setPhoneNumber(response.data.user_details.phoneNumber ? '+'+response.data.user_details.phoneNumber : '');
        setUserProfile(response.data.user_details.userProfile);
        setInsuranceIdTxt(response.data.user_details.insurance ? response.data.user_details.insurance : 0);
        setGenderTxt(response.data.user_details.gender ? response.data.user_details.gender : '');
        setHeightTxt(response.data.user_details.height ? response.data.user_details.height : 0);
        setWeightTxt(response.data.user_details.weight ? response.data.user_details.weight : 0);        
        setIsSession(response.data.session_status ? response.data.session_status : 'false');

      } catch (error) {
        console.error('Error fetching records:', error);
        // Handle error, show an error message, etc.
      }
      
      setShowLoaderPage(false)
    };
    fetchRecords();
  }, []); 

  useEffect(() => {
    const handlePopstate = () => {
      setActiveTab(0); // Reset the active tab to the default tab index
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  const handleTabClick = (index) => {
    setActiveTab(index);
    setShowMainPoint(false);
  };

  useEffect(() => {
    setActiveTab(0); // Set the default active tab when the component mounts
  }, []);

  // Function to process the user data
  const processUserData = () => { 
    if (!RecordData) return []; // If no recordData, return empty array

    return [RecordData].map(user => {
      // Remove empty objects from the conversation array
      const filteredConversation = user.conversation.filter(convo => Object.keys(convo).length !== 0); 
      
      // Check if the answer to the specific question is "yes"
      const emergencyQuestion = filteredConversation.find(convo => 
        convo.question === "Do you need emergency care? (YES/NO)" && convo.answer.toLowerCase() === "yes"
      );

      if (emergencyQuestion) {
        alert("Emergency care is needed!");
      }

      return {
        ...user,
        conversation: filteredConversation
      };
    });
  };
  const [ImageHide, setImageHide] = useState(false); 
  const [showMainPoint, setShowMainPoint] = useState(false);
  const handleInputMainPoint = () => { 
        setShowMainPoint(false);   
        {!PlanOnCareData ? 
        (<>
          setShowMainPoint(false);
          </>
        ):(
          <>
          setShowMainPoint(true);
          </>
        )
      }
    setShowMainPoint(true);
    setPlanOnCareData(PlanOnCareData);
   };
  const handleCancelClickMPoint = () => {
    setShowLoader(false);
    setShowMainPoint(false);
  };
  const handleInputChangeMPoint= (e) => {
    setPlanOnCareData(e.target.value);
  };
  const handleSaveClickMPoint = () => {
    setShowLoader(true);
    setTimeout(() => { 
    setIsEditing(false); 
    handleSaveMPoint();
    }, 2000); 
  };
  const handleSaveMPoint = async (event) => {   
    try {   
      const reqData = JSON.stringify({ session_id: SessionIDCheck, plan_of_care: PlanOnCareData }) ;
   
      const response = await axios.put(getBaseURL+'/update_plan_of_care', reqData, {
        headers: {
          'Content-Type': 'application/json', // Specify content type as application/json
        },
      });
      if( response.data.status == 'success'){
        
          setShowLoader(false); 
          setShowMainPoint(false); 
          setBackReload(true)
          
      } 
    } catch (error) {
      console.error('Error updating summary:', error);
      // Handle error, show an error message, etc.
    }
  };

  return (
    <div>
      {ShowLoaderPage && <LoaderSpinner />} 
      <div className='container-fluid p-0 adminDashboardMain'>
        <SideBarMenu/>
      
        <div className='container-fluid p-0 contentBody'>
        <Header /> 
             <div className="innerBodyBox bgInnerDiv">

              <div className="contentInnerBody">
               
                
                <div className="contentMainBox viewPatientMain"> 
                    <div className="userInfoMainDiv whiteBg innerDivRecords">
                        <div className="imgBox">
                               <b> 
                                      {fullName.split(' ').map(word => word.charAt(0).toUpperCase()).join('') }  
                                </b> 
                        </div>
                        <div className="titleDivRight">
                            <h2 className="capitalizeTxt">{fullName}</h2>
                            <button className="backBtn" onClick={showSummay ? (handleHideSummary) :(handleShowDashboard)}                        
                            ><img src={Icons[0].admin_backBtn} />Back</button>
                            <ul className="userInfoDetails">
                              <li className={GenderTxt ? '' : 'd-none'}>
                                <img src={Icons[0].admin_userIcon} />
                                <label className="capitalizeTxt">{GenderTxt}</label>
                              </li>
                              <li className={DOBTxt ? '' : 'd-none'}>
                                <img src={Icons[0].admin_calendarIcon} />
                                <label>{moment(DOBTxt).format('MMM DD, YYYY')} {' '}({ageCal} years)</label>
                              </li>
                              <li>
                                <img src={Icons[0].admin_mailIcon} />
                                <label className="singleLineEllipsis">{email}</label>
                              </li>
                              <li>
                                <img src={Icons[0].admin_phoneIcon} />
                                <label>{phoneNumber}</label>
                              </li>
                            </ul>
                            <div className="listOptDiv">
                            <ul className="userMedicalInfo">
                              <li>
                                <b>{BMITxt}</b>
                                <label>BMI</label>
                              </li>
                              <li>
                                <b>{WeightTxt  ? WeightTxt : '0'}{' '}<span>lbs</span></b>
                                <label>Weight</label>
                              </li>
                              <li>
                                <b>{HeightTxt ? HeightTxt : '0'}{' '}<span>cm</span></b>
                                <label>Height</label>
                              </li>
                              <li>
                                <b className="InsuranceIDEllipsis">{InsuranceIdTxt ? InsuranceIdTxt : '0'}</b>
                                <label>Insurance ID</label>
                              </li>
                            </ul>
                            <ul className="userMedicalInfo">
                              <li>
                                <b className="text-center">{UserHistoryNumber}</b>
                                <label>Appointments</label>
                              </li>
                            </ul>
                            </div>
                        </div> 
                    </div>

                    <div className={"patientHistoryMain " +(showSummay ? 'd-none':'')}>
                      <div className="whiteBg innerDivRecords ">
                                  <div className="titleDiv">
                                      <h2>All Appointments</h2>
                                      <ul className={"titleRightSideDiv " + (  UserHistoryData.length != 0 ? '':'d-none'  )}> 
                                        <li>   
                                            <div className="table-options">
                                              <label htmlFor="recordsPerPage">Showing</label>
                                              <select id="recordsPerPage" value={recordsPerPage} onChange={handleRecordsPerPageChange}>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                              </select> 
                                            </div>
                                          </li> 
                                      </ul>
                                    </div>
                          { UserHistoryData.length != 0 ? (<>
                              <div className="table-container">
                                  <table id="patientTable">
                                    <thead>
                                      <tr>
                                        <th>Illnesses</th>
                                        <th  style={{textAlign:'center'}}>Consultation Date</th>
                                        <th  style={{textAlign:'right', paddingRight: '50px'}}>Action</th> 
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {renderTableBody()}
                                    </tbody>
                                  </table>
                                </div>
                                </>):(<>
                                  <div className="titleDiv text-center table-container d-flex justify-content-center align-items-center nodataRecords">
                                      <h2> No table record </h2>
                                      </div>
                                  </>)
    }
                      </div> 
                            
                        <div className={"paginationMain " +(showSummay ? 'd-none':'') + (  UserHistoryData.length != 0 ? '':'d-none'  )}>
                                {renderPagination()}
                                <span className="totalPageDiv">Total Pages: {totalPages}</span>
                        </div>
                    </div>

                     <div className={"patientSummaryMain " +(!showSummay ? 'd-none':'')}>
                 
    <div className="innerDivRecords" >
 
		<nav>
			<div className="nav nav-tabs mb-0" id="nav-tab" role="tablist">
				<button className={`nav-link ${activeTab === 0 ? 'active' : ''}`}
            onClick={() => handleTabClick(0)} id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Summary</button>
				<button className={`nav-link ${activeTab === 1 ? 'active' : ''}`}
            onClick={() => handleTabClick(1)} id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Plan of Care</button>
				<button className={`nav-link ${activeTab === 2 ? 'active' : ''}`}
            onClick={() => handleShowChat(2)} id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Pre-Diagnosis</button>
			  <button className={`nav-link ${activeTab === 3 ? 'active' : ''}`}
            onClick={() => handleShowChat(3)} id="nav-reportData-tab" data-bs-toggle="tab" data-bs-target="#nav-reportData" type="button" role="tab" aria-controls="nav-reportData" aria-selected="false">Reports</button>
			</div>
		</nav>
		<div className="whiteBg tab-content p-3 bg-white" id="nav-tabContent" >
			<div className={`tab-pane fade ${activeTab === 0 ? 'active show' : ''}`} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
        <div className="despInfoDiv">
          <h2>Summary</h2>
          <ul>
            <li>
              <label>Consultation Date</label>
              <b> {moment(RecordData.created_on).format('MMM DD, YYYY')}</b>
            </li>
            <li>
              <label>Illnesses</label>
              <b>{RecordData.illness}</b>
            </li>
          </ul>
        </div>
				
          {EmergencyQuest ? (<><p className="innerDivBox urgentTxt"><img src={Icons[0].admin_icon911} /> Urgent: Patient has called for emergency assistance.</p></>) :  RecordData.chatbot_summary ? <p className="innerDivBox">{RecordData.chatbot_summary} </p> :  <p className="innerDivBox"> - </p> }
          
          
			</div>
			<div className={`tab-pane fade ${activeTab === 1 ? 'active show' : ''}`} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
      <div className="despInfoDiv">
          <h2>Plan of Care  
          {!EmergencyQuest ? ( <a onClick={handleInputMainPoint} style={{marginLeft:'10px', cursor:'pointer'}}  className={ImageHide ? "editBtnNew " : "editBtnNew "}>   Edit <img src={!showMainPoint ? Icons[0].editIcon : Icons[0].editRedIcon } /></a>) :  ('')}
          </h2>
          </div>
          {!showMainPoint ? (
                  <>     
                   {EmergencyQuest ? <p className="innerDivBoxPC urgentTxt"><img src={Icons[0].admin_icon911} /> Urgent: Patient has called for emergency assistance.</p> : PlanOnCareData ? <pre className="innerDivBoxPC">{PlanOnCareData}</pre> : '-' }  
                 </>
              ) :(
                <div className='textareaSummary innerDivBox'>
                  <textarea value={PlanOnCareData} onChange={handleInputChangeMPoint} style={{    height: '80%', width:' 100%'}} /> 
                  <hr></hr>
                 <div className='editSaveOpt' >
                    {ShowLoader ? (<>
                      <button  > Loading
                      <div className="spinner-border text-light" role="status">
                       <span className="visually-hidden">Loading...</span>
                    </div> 
                    </button>
                    </>):(<>
                      <button onClick={handleSaveClickMPoint}>  
                    Save Changes
                    </button>
                    </>)

                    }
                    
                    <button onClick={handleCancelClickMPoint}>Cancel</button>
                  </div>
                </div> 

              )}
      </div>
			<div className={`tab-pane fade ${activeTab === 2 ? 'active show' : ''}`} id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
      <div className="despInfoDiv">
          <h2>Pre-Diagnosis   </h2>
          </div>
        <div className="chatbotConversation  pb-0">
      <div className="messageListBox mb-0">
      {answerLoader ? ( 
                    <>
                    <div className= "quesListDiv " >
                        <div className={"chatbot aiQuest "}>
                            <span className="imgBox">
                              <img src={Icons[0].chatbot} alt="Chatbot" />
                            </span>
                            <span> 
                                <div className="showLoaderDot">
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </div> 
                            </span>
                          </div>
                    </div>
                    </>):(<>
                    
                      <div className="chatbot">
                <span className="imgBox">
                  <img src={Icons[0].chatbot} alt="Chatbot" />
                </span>
                <span>
                  <b className="AI_dateTime">
                    AI 
                  </b> 
                      <label className="quesTxt">Hi, How may I help you today? You may select or type a symptom you’re facing currently.</label>
                      <div className="questOpt">
                        <b>Please select any</b>
                        <ul className={!selectedIllness ? 'illnessesListOpt' : 'illnessesListOpt selected'}>
                          {illnessesList?.map((item, index) => (
                            <li
                              key={index}
                              className={selectedIllness === item ? 'active' : 'disabled'} 
                              style={{ pointerEvents: selectedIllness && selectedIllness !== item ? 'none' : 'auto' }}
                            >
                              <b>{item}</b>
                            </li>
                          ))}
                        </ul>
                      </div>
                     
                </span>
              </div>

           {ChatHistory.length > 0 && (
                <div className="quesListDiv">
                  {ChatHistory.map((q, index) => (
                    <div key={index}>
                      <div className="chatbot aiQuest">
                        <span className="imgBox">
                          <img src={Icons[0].chatbot} alt="Chatbot" />
                        </span>
                        <span> 
                              <b className="AI_dateTime">
                                AI 
                              </b>
                              <label className="quesTxt">{q.question}</label>
                             
                        </span>
                      </div>
                      {q.answer && (
                        <div className="chatbot userReply">
                          <span className="imgBox">
                            <b>{fullName.split(' ').map(word => word.charAt(0).toUpperCase()).join('')}</b>
                          
                          </span>
                          <span>
                            <b className="AI_dateTime">
                              YOU 
                            </b>
                            <label className="quesTxt">{q.answer}</label>
                          </span>
                        </div>
                      )} 
                    </div>
                  ))}
                </div>
              )} 
                    
                    </>)}
              

            </div> 
     </div>
		 
			</div>
      <div className={`tab-pane fade ${activeTab === 3 ? 'active show' : ''}`} id="nav-reportData" role="tabpanel" aria-labelledby="nav-reportData-tab">
      <div className="despInfoDiv">
          <h2>Reports </h2>
          </div>
          <div className="innerDivBoxPC"> 
             {/* {reportData ? (
            <ol className="reportListOptLink">
               {Object.keys(reportData).map((category) => (
                  <React.Fragment key={category}>
            
                     
                    {reportData[category].file_urls.map((url, index) => (
                      url ? (
                        <li key={index}>
                            <a href={url} target="_blank" rel="noopener noreferrer" style={{ textTransform: "capitalize", textDecoration: "none" }}>
                              {category.replace('_', ' ')} {category === 'upload_report' ? `File ${index + 1}` : ''} <img src={Icons[0].pdf_Icon} alt="PDF Icon" style={{ width: "24px" }} />
                            </a>
                        </li>
                      ) : ( <p style={{textAlign:'center'}}>No reports found</p> )
                    ))}
                  </React.Fragment>
                ))}
            </ol> ) : (
              <ol className="reportListOptLink">
                <p style={{textAlign:'center'}}>No reports found</p>
              </ol>
            )}   */}

{reportData ? (
    <ol className="reportListOptLink">
        {Object.keys(reportData).map((category) => (
            <React.Fragment key={category}>
                {/* Displaying the file URLs with texts */}
                {reportData[category].file_urls.map((url, index) => (
                    url ? (
                        <li key={index}>
                            <b style={{ textTransform: "capitalize", textDecoration: "none" }}>{category.replace('_', ' ')}</b>
                            <a href={url} target="_blank" className="downloadLink" rel="noopener noreferrer" style={{ textTransform: "capitalize", textDecoration: "none" }}>
                                Download
                                <img src={Icons[0].pdf_Icon} alt="PDF Icon" style={{ width: "24px", marginLeft: "10px" }} />
                            </a>
                            {reportData[category].texts[index] && (
                                <p style={{ margin: "5px 0", textTransform: "capitalize" }}>
                                    {reportData[category].texts[index]}
                                </p>
                            )}
                        </li>
                    ) : null
                ))}

                {/* Fallback if no reports found */}
                {reportData[category].file_urls.length === 0 && reportData[category].texts.length === 0 && (
                    <p style={{ textAlign: 'center' }}>No reports found</p>
                )}
            </React.Fragment>
        ))}
    </ol>
) : (
    <ol className="reportListOptLink">
        <p style={{ textAlign: 'center' }}>No reports found</p>
    </ol>
)}


          </div>
          
          </div>
		</div>
	</div>
                    </div> 
                </div>
                
              </div>
                 
             </div>
        </div> 
      </div>
    </div>
  );
}

export default ViewPatientProfile;