import  React, { useState, useRef, useEffect  } from "react"; 
import { Link, useLocation, useNavigate, useHistory } from "react-router-dom"; 
import { Icons } from "../../assest/icons";
import Header from './header';
import LoaderSpinner from '../loaderSpinner';
import SideBarMenu from './sideBarMenu';
import { getBaseURL } from '../baseUrl';
import axios from 'axios'; 

function AdminDashboard() {  
 let navigate = useNavigate();
  const routeChange  = (id) => {  
    navigate('/admin/patientprofile', {
      state: { id: id, currentPageNo: currentPage, searchString: searchText}
    }); 
  } 
 
  const [TotalUser, setTotalUser] = useState('');
  const location = useLocation();  
  const initialPage = location.state?.backCurrentPage || 1; 
  const searchTextPage = location.state?.searchTxt || '';  
  const [ShowLoader, setShowLoader] = useState(false);
  const [patientDataList, setPatientDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [currentPageGroup, setCurrentPageGroup] = useState(1); 
  const [perPage, setPerPage] = useState(10);  
 
  const [searchText, setSearchText] = useState( location.state?.searchTxt || ''); 
  const [CheckString, setCheckString] = useState(searchText.length >0 ? true : false);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);  
    setCheckString(e.target.value.length > 0 ? true : false)  
  };

  const handleFilterIconClick = () => {
    setSearchText(searchText); 
    fetchData(1, recordsPerPage, searchText);
  };
  const handleResetSearch = () => {
    setSearchText(''); 
    setCurrentPage(1);
    setCheckString(false);
    fetchData(currentPage, recordsPerPage, searchText);
  };

  useEffect(() => {  
    fetchData(currentPage, recordsPerPage, searchText);
  }, [currentPage, recordsPerPage, searchText]);

  const fetchData = async (page, perPage, querytxt) => {
    setShowLoader(true)
    try {
      const response = await axios.get(getBaseURL+'/users_page', {
        params: {
          page: page,
          per_page: perPage, 
          query:querytxt,
        },
      }); 
      setPatientDataList(response.data.users);
      setTotalPages(response.data.total_pages);
      setTotalRecords(response.data.total_records);
      setShowLoader(false)

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePageChange = (page) => { 
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };
 
  const handlePrevGroup = () => {
    if (currentPageGroup > 1) {
      setCurrentPageGroup(currentPageGroup - 1);
      setCurrentPage((currentPageGroup - 2) * 3 + 1);
    }
  };

  const handleNextGroup = () => { 
    const maxGroup = Math.ceil(totalPages / 3);
    if (currentPageGroup < maxGroup) {
      setCurrentPageGroup(currentPageGroup + 1);
      setCurrentPage(currentPageGroup * 3 + 1); 
    }
  };
 
   // Function to get the first and last characters
   const getFirstAndLastChars = (str) => {
      const words = str.split(' ');
      if (words.length === 0) return '';
    
      const firstChar = words[0].charAt(0).toUpperCase();
      const lastChar = words[words.length - 1].charAt(0).toUpperCase();
    
      return `${firstChar}${lastChar}`;
  };
  
  const renderTableBody = () => {
    if (!patientDataList || patientDataList.length === 0) {
      return (
        <tr>
          <td colSpan="4" style={{ textAlign: 'center' }}>No data found</td>
        </tr>
      );
    }
  
    const start = (currentPage - 1) * recordsPerPage;
    const end = start + recordsPerPage;
    const pageData = patientDataList.slice(start, end);
  
    return patientDataList.map((patient, index) => (
      <tr key={index} onClick={() => routeChange(patient.user_id)} style={{ cursor: 'pointer' }} className={patient.session === 'true' ? 'active' : ''}>
        <td>
          <span className="d-flex align-items-center patientFullnameTxt">
            <b className="userImgIconCircleDiv"> 
              {getFirstAndLastChars(patient.fullName)}
              {/* {patient.fullName.split(' ').map(word => word.charAt(0).toUpperCase()).join('')} */}
            </b>
            <span className="fullnameTxt">{patient.fullName}</span>
          </span>
        </td>
        <td>{patient.email}</td>
        <td>{patient.phoneNumber ? '+'+patient.phoneNumber : '-'}</td>
        <td><span className="InsuranceIDEllipsis">{patient.insurance ? patient.insurance : '-'}</span></td>
      </tr>
    ));
  };
 
  const renderPagination = () => {
    if (!patientDataList || patientDataList.length === 0) {
      return null; // Return null to hide the pagination when there is no data
    }
  
    let pages = [];
    const startPage = (Math.floor((currentPage - 1) / 3) * 3) + 1;
    const endPage = Math.min(startPage + 2, totalPages);
  
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          className={`page-button ${i === currentPage ? 'active' : ''}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }
  
    return (
      <div className="pagination">
        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
          Previous
        </button>
        {pages}
        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    );
  };
  
 
useEffect(() => { 
setShowLoader(true) 
}, []);



  return (
    <div>
     {ShowLoader && <LoaderSpinner />} 
      <div className='container-fluid p-0 adminDashboardMain'>
        <SideBarMenu/>
      
        <div className='container-fluid p-0 contentBody'>
             <Header />
             <div className="innerBodyBox bgInnerDiv patientDataListMain">

              <div className="contentInnerBody">
                <div className="titleDiv">
                  <h2>All Patients</h2>
                 

                 <div className="">
                      <div  className="leftDiv d-inline-block searchAdminUser">
                              <div className="inputDivBox">
                                <img src={Icons[0].admin_searchIcon} alt="search-icon" />
                                <input
                                  type="text"
                                  placeholder="Search patient name, email, phone"
                                  value={searchText}
                                  onChange={handleSearchChange}
                                />
                               <img
                                 className={"reset-button " + (CheckString ? 'b':'d-none')}
                                  src={Icons[0].admin_deleteIcon}
                                  alt="filter-icon"
                                  onClick={handleResetSearch}
                                  style={{ cursor: 'pointer' }}
                                />
                            </div>
                      </div>
                      <ul className="titleRightSideDiv">
                        <li>Total Patients: {!totalRecords ? '0' : totalRecords}</li>
                        <li>   
                            <div className="table-options">
                              <label htmlFor="recordsPerPage">Showing</label>
                              <select id="recordsPerPage"
                               value={perPage} onChange={(e) => setPerPage(Number(e.target.value))}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select> 
                            </div>
                          </li> 
                      </ul>
                  </div>

                </div>
                <div className="contentMainBox"> 
                        <div className="table-container">
                          <table id="patientTable">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Insurance ID</th>
                              </tr>
                            </thead>
                            <tbody>
                              {renderTableBody()}
                            </tbody>
                          </table>
                        </div>
                        <div className="paginationMain">
                             {renderPagination()}
                             <span className={"totalPageDiv "+ (!totalPages ? 'd-none' : '')}>Total Pages: {totalPages}</span>
                        </div>
                </div>
              </div>
                 
             </div>
        </div> 
      </div>
    </div>
  );
}

export default AdminDashboard;