import React, { useState } from "react"; 
import PageOne from "./pageone";
import PageTwo from "./pagetwo";  
import Header from '../header';
 
// import tachyons from "tachyons";
import MultiStepProgressBar from "./MultiStepProgressBar/MultiStepProgressBar";

function MainStep() {
  const [page, setPage] = useState("pageone");

  const nextPage = (page) => {
    setPage(page);
  };

  const nextPageNumber = (pageNumber) => {
    switch (pageNumber) {
      case "1":
        setPage("pageone");
        break;
      case "2":
        setPage("pagetwo");   
        break; 
       
    }
  };

  return (
    <div > 
     <div className='container-fluid p-0 bgInnerDiv'>
        <Header />
        <div className='container-fluid p-0 contentBody  '>           
      <MultiStepProgressBar page={page} onPageNumberClick={nextPageNumber} />
      {
        {
          pageone: <PageOne onButtonClick={nextPage} />,
          pagetwo: <PageTwo onButtonClick={nextPage} />, 
        }[page]
      }
    </div>
    </div> 
    </div> 
  );
}

export default MainStep;
