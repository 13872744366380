import  React, { useState, useRef, useEffect  } from "react";   
import { Link, useNavigate } from "react-router-dom";
import { Icons } from "../../assest/icons"; 
import { ToastContainer, toast } from "react-toastify"; 
import LeftSideLogin from "../leftsidelogin";
import { useGoogleLogin } from "@react-oauth/google";
import { getBaseURL } from '../baseUrl';
 
 
function AdminLoginScreen() { 
  
  const [email, setEmail] = useState('');  
  const [showAccessToken, setAccessToken] = useState('');  
  const [UserLoginStatus, setUserLoginStatus] = useState('');  
  const [password, setPassword] = useState('');
  const [selectedToggle, setSelectedToggle] = useState(true);
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [ShowLoader, setShowLoader] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [UserId, setUserId] = useState('');  
  const [UserData, setUserData] = useState([]);
  const [passwordType, setPasswordType] = useState("password");

  const handleClickPassword = (event) => { 
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const handlePasswordChange = (event) => {
    const { value } = event.target;
    setPassword(value);
    setErrors('');
};
const handleEmailChange = (event) => {
  const { value } = event.target;
  setEmail(value);
  setErrors('');
};
  useEffect(() => {
    // Check if there is a stored rememberMe preference
    const storedRememberMe = localStorage.getItem('rememberMe');
    if (storedRememberMe) {
        setRememberMe(JSON.parse(storedRememberMe));
    }
}, []);

  const handleResetForm= () => { 
    setEmail(''); 
    setPassword(''); 
    setErrors('');
  };
  const nav = useNavigate();
  const routeChange = () =>{  
    nav("/admin/dashboard", { state:UserData}); 
  }

  const handleSubmit = (event) => {

    event.preventDefault();

    // Simple form validation
    let formErrors = {};
    if (!email.trim()) {
      formErrors.email = 'Please enter your email';
    } 
    if (!password.trim()) {
        formErrors.password = 'Please enter your password';
   }
    if (!isValidEmail(email)) {
      formErrors.email = 'Please enter a valid email address'; 
  }
    if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        return;
    }
    setShowLoader(true)
    // If validation passes, send data to API
    const data = { 
      email: email, 
      password: password,
    }; 

  // Here, you would replace 'apiEndpoint' with your actual API endpoint
  fetch(getBaseURL+'/admin/signin ', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
  })
   
  .then(response => response.json())
  .then(data => {
    if( data.status == 'success'){
      toast.success("User Signin successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      }); 
      setUserData(data)
      
      localStorage.setItem('UserIdAdmin',  JSON.stringify(data.user_id) );  
      if (rememberMe) {
        localStorage.setItem('rememberMe', JSON.stringify(true));
      } else {
          localStorage.removeItem('rememberMe');
      }
      localStorage.setItem('UserProfileAdmin', JSON.stringify(data));
      setShowLoader(false)
      handleResetForm()
      routeChange()
    }
    if( data.status == 'error'){ 
        toast.error(data.message, {
           position: toast.POSITION.TOP_RIGHT,
          }); 
          setShowLoader(false)
    }
 
      // Optionally, you can redirect the user or show a success message
  })
  .catch((error) => {
      console.error('Error:', error);
      // Optionally, you can display an error message to the user
  });
};
const isValidEmail = (email) => {
  // Regular expression to validate email format
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};
const isValidNewPassword = (password) => {
  return password.length >= 8;
};

const googleLogin = useGoogleLogin({
  onSuccess: async (tokenResponse) => { 
    setAccessToken(tokenResponse.access_token);
    checkGoogleLogin(tokenResponse.access_token)
  },
  onError: (errorResponse) => console.log(errorResponse),
});
const checkGoogleLogin = async (event) => {   
    // If validation passes, send data to API
    const data = {     
      access_token: event,
     }; 

  // Here, you would replace 'apiEndpoint' with your actual API endpoint
  fetch(getBaseURL+'/send_access_token', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
  })
   
  .then(response => response.json())
  .then(data => {
    if( data.status == 'success'){ 
      toast.success("Login successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });  
      localStorage.setItem('UserProfileAdmin', JSON.stringify(data));
      if( data.isExist == 'false' ){
        nav("/filluserinfo"); 
      }else{
        nav("/dashboard"); 
      } 
    }
    if( data.status == 'error'){ 
        toast.error(data.message, {
           position: toast.POSITION.TOP_RIGHT,
          }); 
    }
 
      // Optionally, you can redirect the user or show a success message
  })
  .catch((error) => {
      console.error('Error:', error);
      // Optionally, you can display an error message to the user
  });
};

const handleUserRemove = async () => { 
  const data = '6633ad9968a6007798ae711c';
  const formUploadFiles = new FormData();
    formUploadFiles.append('user_id', data); 
    const data1 = {     
      user_id: data,
      illness:"Bleeding",
     }; 
// Here, you would replace 'apiEndpoint' with your actual API endpoint
fetch(getBaseURL+'/chatbot', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data1),
})

  .then(response => response.json())
  .then(data => {
    if( data.status == 'success'){
       console.log('success:', data);
    } 
  })
  .catch((error) => {
    console.log('Error:', error);
  });
  
};

const handleUserRemove1 = async () => { 
  const userIdRemove = '664633e668a60030b1595d7e';  
  fetch(getBaseURL+'/delete_by_id/'+userIdRemove, {
    method: 'DELETE',  
  })
  .then(response => response.json())
  .then(data => {
    if( data.status == 'success'){
       
    } 
  })
  .catch((error) => {
    console.log('Error:', error);
  });
  
};
  return (
    <div>
      <div className='container-fluid p-0 bgImageUrl'>
       
        <div className='container p-0 contentBody loginScreen'>

       <LeftSideLogin />

          <div className="col-lg-5 d-inline-block  position-relative">
            <div className="loginBoxDiv">
            <h3  className="titleDespTxt">Admin Sign in</h3>
            <form  onSubmit={handleSubmit} autoComplete="off">
            
                              
           <div className='inputGroupDiv'> 
                <div className="inputTxtBox registerInputDiv">
                   <img src={Icons[0].mailImg} className="logoImg" alt="" />
                    <input type="text" value={email} placeholder="Email id" autoComplete="off" onChange={handleEmailChange}/>  
                 
                </div>
                {errors.email && <span  className="errorTxt" >{errors.email}</span>}
          </div>
                             
          <div className='inputGroupDiv'> 
                <div className="inputTxtBox registerInputDiv">
                   <img src={Icons[0].lockedImg} className="logoImg" alt="" />
                    <input type={passwordType}  value={password} placeholder="Password" autocomplete="new-password" onChange={handlePasswordChange}/>   
                    <label className='eyePwdIcon' onClick={handleClickPassword}>  
                      <img src={passwordType === "password" ? ( Icons[0].closeEye  ) : ( Icons[0].opneEye  )} className="logoImg" alt="" />
                    </label>                
                </div>
                {errors.password && <span  className="errorTxt" >{errors.password}</span>}
          </div>
 
              {ShowLoader ? (<>
                      <button  className="signInBtn" > Loading
                      <div className="spinner-border text-light  " role="status">
                       <span className="visually-hidden">Loading...</span>
                    </div> 
                    </button>
                    </>):(<>
                      <button className="signInBtn" type="submit" >Sign In</button>
                    </>)}
            
             
            </form>
                
            </div>
          </div>
          
        </div>
      
      </div>
    </div>
  );
}

export default AdminLoginScreen;