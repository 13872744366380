import  React, { useState, useRef, useEffect  } from "react"; 
import { Link, useLocation, useNavigate } from "react-router-dom"; 
import { Icons } from "../../assest/icons";
import Header from './header';
import LoaderSpinner from '../loaderSpinner';
import SideBarMenu from './sideBarMenu'; 
import {getBaseURL} from '../baseUrl';
 
 
function AdminUser() { 
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/admin/patientprofile`;
    navigate(path);
  }
  const nav = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
 
  const [ShowLoader, setShowLoader] = useState(false);

  const [adminDataList, setAdminDataList] = useState([]);
  const [patientDataList, setPatientDataList] = useState([]);
  const [TotalUser, setTotalUser] = useState('');

  const totalPages = Math.ceil(adminDataList.length / recordsPerPage);
  const totalPageGroups = Math.ceil(totalPages / 3);
  const currentPageGroup = Math.ceil(currentPage / 3);

  const handleRecordsPerPageChange = (event) => {
    setRecordsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNextGroup = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevGroup = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleRowClick = (id) => { 
    nav("/admin/adminusersinfo", { state: id }); 
  };
  
  const openAddAdmin = () => { 
    nav("/admin/adduseradmin"); 
  };
  const renderTableBody = () => {
    const start = (currentPage - 1) * recordsPerPage;
    const end = start + recordsPerPage;
    const pageData = adminDataList.slice(start, end);

    return pageData.map((patient, index) => (
      <tr key={index}  onClick={() => handleRowClick(patient.user_id)} style={{cursor:'pointer'}}>
        <td>{patient.fullName}</td>
        <td>{patient.email}</td>
        <td>{'+'+patient.phoneNumber}</td>
        <td>{patient.userProfile}</td>
      </tr>
    ));
  };

  const renderPagination = () => {
    let pages = [];
    const startPage = (currentPageGroup - 1) * 3 + 1;
    const endPage = Math.min(startPage + 2, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          className={`page-button ${i === currentPage ? 'active' : ''}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    return (
      <div className="pagination">
        <button
          onClick={handlePrevGroup}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {pages}
        <button
          onClick={handleNextGroup}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      
      </div>
    );
  };
  const checkAllAdminUser = async () => {
  
    // Here, you would replace 'apiEndpoint' with your actual API endpoint
    fetch(getBaseURL+'/admin/get_all_admin_users', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }, 
    })

      .then(response => response.json())
      .then(data => { 
        setAdminDataList(data) 
        setTotalUser(data.length)
        setShowLoader(false) 
      })
      .catch((error) => {
        //console.error('Error:', error); 
      });
  };
 
useEffect(() => { 
  setShowLoader(true)
  checkAllAdminUser() 
}, []); 
  return (
    <div>
    {ShowLoader && <LoaderSpinner />} 
      <div className='container-fluid p-0 adminDashboardMain'>
        <SideBarMenu/>
      
        <div className='container-fluid p-0 contentBody'>
             <Header />
             <div className="innerBodyBox bgInnerDiv">

              <div className="contentInnerBody">
                <div className="titleDiv">
                  <h2>Admin Users</h2>
                  <ul className="titleRightSideDiv">
                    <li className="addAdminBtn"><a className="displayFlexCenter" onClick={openAddAdmin}> <img src={Icons[0].admin_addUser} className="logoImg companyLogo" alt="" />
                          Add Admin
                      </a></li>
                    <li>Total: {TotalUser}</li>
                    <li>   
                        <div className="table-options">
                          <label htmlFor="recordsPerPage">Showing</label>
                          <select id="recordsPerPage" value={recordsPerPage} onChange={handleRecordsPerPageChange}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select> 
                        </div>
                      </li> 
                  </ul>
                </div>
                <div className="contentMainBox"> 
                

                  <div className="table-container">
                          <table id="patientTable">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Profile</th>
                              </tr>
                            </thead>
                            <tbody>
                              {renderTableBody()}
                            </tbody>
                          </table>
                        </div>
                        <div className="paginationMain">
                             {renderPagination()}
                             <span className="totalPageDiv">Total Pages: {totalPages}</span>
                        </div>
                
               
                       
                </div>
              </div>
                 
             </div>
        </div> 
      </div>
    </div>
  );
}

export default AdminUser;