import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { GoogleOAuthProvider } from "@react-oauth/google";
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
const GoogleClientID = '225381649148-omsgc459t2en6ch6v5q3ai6nil201fcr.apps.googleusercontent.com';  {/* Add Here GoogleClientID*/}
root.render(
  <React.StrictMode>                
     {/* <GoogleOAuthProvider clientId="225381649148-omsgc459t2en6ch6v5q3ai6nil201fcr.apps.googleusercontent.com"> */}
     <GoogleOAuthProvider clientId={GoogleClientID}>   {/* Call GoogleClientID*/}
     
       <App /> 
     </GoogleOAuthProvider>
  </React.StrictMode>
);
 
reportWebVitals();
