import  React, { useState, useRef, useEffect, useContext  } from "react"; 
import { Link, useLocation, useNavigate, useHistory } from "react-router-dom"; 
import { Icons } from "../../assest/icons";
import Header from './header';
import LoaderSpinner from '../loaderSpinner';
import SideBarMenu from './sideBarMenu';
import { getBaseURL } from '../baseUrl';
import axios from 'axios'; 
import { SearchContext } from './SearchContext';

function SearchFilter() { 
 const { searchQuery, clearSearchQuery} = useContext(SearchContext);
 
 let navigate = useNavigate();
  const routeChange  = (id) => {  
    const searchQueryNext = searchQuery;
    clearSearchQuery()
    navigate('/admin/patientprofile', {
      state: { id: id, currentPageNo: currentPage, searchTxt: searchQueryNext }
    }); 
  } 
 
  const [TotalUser, setTotalUser] = useState('');
  const location = useLocation();  
  const initialPage = location.state?.backCurrentPage || 1;   
  const [searchTxtCheck, setSearchTxtCheck] = useState('');
  const [ShowLoader, setShowLoader] = useState(false);
  const [patientDataList, setPatientDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [currentPageGroup, setCurrentPageGroup] = useState(1); 
  const [perPage, setPerPage] = useState(10); 
  
  useEffect(() => {
    if (searchQuery) { 
      setCurrentPage(1); // Reset current page to 1 when a search query is applied
    }  
  }, [searchQuery]);
  useEffect(() => {
    fetchData(currentPage, recordsPerPage, searchQuery);
  }, [currentPage, recordsPerPage, searchQuery]);
 
  const fetchData = async (page, perPage, filterquery) => {
   setShowLoader(true);
    try {
      const response = await axios.get(`${getBaseURL}/users_page`, {
        params: {
          page: page,
          per_page: perPage,
          query: filterquery,
        },
      });
      setPatientDataList(response.data.users);
      setTotalPages(response.data.total_pages);
      setTotalRecords(response.data.total_records);
      setShowLoader(false); 
    } catch (error) {
      console.error('Error fetching data:', error);
      setShowLoader(false);
    }
  };

 
  
  const handlePageChange = (page) => {
    setCurrentPage(page); 
  };

  const handlePrevGroup = () => {
    if (currentPageGroup > 1) {
      setCurrentPageGroup(currentPageGroup - 1);
      setCurrentPage((currentPageGroup - 2) * 3 + 1);
    }
  };

  const handleNextGroup = () => { 
    const maxGroup = Math.ceil(totalPages / 3);
    if (currentPageGroup < maxGroup) {
      setCurrentPageGroup(currentPageGroup + 1);
      setCurrentPage(currentPageGroup * 3 + 1); 
    }
  };

  // const routeChange = (userId) => {
  //   history.push(`/patient/${userId}`);
  // };

  const renderTableBody = () => {
    const start = (currentPage - 1) * recordsPerPage;
    const end = start + recordsPerPage;
    const pageData = patientDataList.slice(start, end);

    return patientDataList.map((patient, index) => (
      <tr key={index} onClick={() => routeChange(patient.user_id)} style={{ cursor: 'pointer' }} className={patient.session === 'true' ? 'active' : ''}>
        <td><span className="d-flex align-items-center">
              <b className="userImgIconCircleDiv">{patient.fullName.split(' ').map(word => word.charAt(0).toUpperCase()).join('') }  </b>
                {patient.fullName}
              </span>
        </td>
        <td>{patient.email}</td>
        <td>{patient.phoneNumber ? patient.phoneNumber : '-'}</td>
        <td>{patient.insurance ? patient.insurance : '-'}</td>
      </tr>
    ));
  };

  const renderPagination = () => { 
    let pages = [];
    const startPage = (Math.floor((currentPage - 1) / 3) * 3) + 1;
    const endPage = Math.min(startPage + 2, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          className={`page-button ${i === currentPage ? 'active' : ''}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    return (
      <div className="pagination"> 
          <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
          Previous
        </button>
        {pages}
        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    );
  };
 
useEffect(() => { 
setShowLoader(true) 
}, []);



  return (
    <div>
     {ShowLoader && <LoaderSpinner />} 
      <div className='container-fluid p-0 adminDashboardMain'>
        <SideBarMenu/>
      
        <div className='container-fluid p-0 contentBody'>
             <Header /> 
             <div className="innerBodyBox bgInnerDiv patientDataListMain">

              <div className="contentInnerBody">
                <div className="titleDiv">
                  <h2>All Patients</h2>
                  <ul className="titleRightSideDiv">
                    <li>Total Patients: {totalRecords}</li>
                    <li>   
                        <div className="table-options">
                          <label htmlFor="recordsPerPage">Showing</label>
                          <select id="recordsPerPage"
                          //  value={recordsPerPage} onChange={handleRecordsPerPageChange}  
                           value={perPage} onChange={(e) => setPerPage(Number(e.target.value))}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select> 
                        </div>
                      </li> 
                  </ul>
                </div>
                <div className="contentMainBox">
                      {/* <div className="table-options">
                          <label htmlFor="recordsPerPage">Show</label>
                          <select id="recordsPerPage" value={recordsPerPage} onChange={handleRecordsPerPageChange}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          <label>records</label>
                        </div> */}
                        <div className="table-container">
                          <table id="patientTable">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Insurance ID</th>
                              </tr>
                            </thead>
                            <tbody>
                              {renderTableBody()}
                            </tbody>
                          </table>
                        </div>
                        <div className="paginationMain">
                             {renderPagination()}
                             <span className="totalPageDiv">Total Pages: {totalPages}</span>
                        </div>
                </div>
              </div>
                 
             </div>
        </div> 
      </div>
    </div>
  );
}

export default SearchFilter;