import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify"; 
import Header from './header'; 
import axios from 'axios';
import { Icons } from "../../assest/icons";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment'; 
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import SideBarMenu from './sideBarMenu'; 
import {getBaseURL} from '../baseUrl';
 

function AddAdminUser() {
  let navigate = useNavigate();
  const locationi = useLocation(); 
  const [userid, setuserid] = useState(locationi.state);
 
  const [userProfileData, setUserProfileData] = useState([]);  
 
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userProfileTxt, setUserProfileTxt] = useState('admin');  
  const [specialty, setSpecialty] = useState('');
  const [selectedToggle, setSelectedToggle] = useState(true);
  const [DOBTxt, setDOBTxt] = useState(''); 
  const [InsuranceIdTxt, setInsuranceIdTxt] = useState('');
  const [GenderTxt, setGenderTxt] = useState('');
  const [originalProfile, setOriginalProfile] = useState({});
  const [isEditing, setIsEditing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');   
  var years = moment().diff(DOBTxt);
  const ageCal = moment().diff(DOBTxt, 'years');  

  const [AgeTxt, setAgeTxt] = useState(ageCal);  
  const [WeightTxt, setWeightTxt] = useState('');
  const [HeightTxt, setHeightTxt] = useState('');
  const [ShowLoader, setShowLoader] = useState(false); 

  const [UserId, setUserId] = useState(userProfileData.user_id);  
  const [showCalendar, setShowCalendar] = useState(false);  
  const [isValid, setIsValid] = useState(false);
  const [focused, setFocused] = React.useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const handleClickPassword = (event) => { 
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const handlePasswordChange = (event) => {
    const { value } = event.target;
    setPassword(value);
    setError('');
};
  
  const onChangeCalendar = (date) => {     
    setDOBTxt(formatDate(date));
    setShowCalendar(false); 
};
  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
};
const formatDate = (date) => { 
  return date ? moment(date).format("YYYY-MM-DD") : '';
};
  const handleChangeCancel = () => {
    setIsEditing(!isEditing);
};
const handleGenderChange = (event) => {
  let { value } = event.target; 
      // Allow delete action
      if (value === '') {
        setGenderTxt(''); 
    } 
    // Convert input to uppercase for comparison
    value = value.toUpperCase();
  // Allow only 'M' or 'F' characters
  if ( value === 'M' || value === 'F') { 
      setGenderTxt(value === 'M' ? 'Male' :  'Female');
  }
};
 
const handleEmailChange = (event) => {
  const { value } = event.target;
  setEmail(value);
  setError('');
};

const handleSubmitProfile = (event) => { 

 event.preventDefault(); 
  // Simple form validation
  let formErrors = {}; 
  if (!fullName.trim()) {
    formErrors.fullName = 'Please enter your full name';
}
if (!email.trim()) {
    formErrors.email = 'Please enter your email';
}
  if (!phoneNumber.trim()) {
    formErrors.phoneNumber = 'Please enter your phone number';
  }
  if (!DOBTxt.length > 0) {
    formErrors.DOBTxt = 'Please select a date';
  }  
  if (!GenderTxt.trim()) {
      formErrors.GenderTxt = 'Please enter your sex';
  }
  if (!specialty.trim()) {
      formErrors.specialty = 'Please enter your specialty';
  }   
  if (!userProfileTxt.trim()) {
      formErrors.userProfileTxt = 'Please enter your status';
  }
  if (!password.trim()) {
      formErrors.password = 'Please enter the admin password';
  } 
  if (Object.keys(formErrors).length > 0) {
      setError(formErrors);
      return;
  }
  setShowLoader(true)
  // If validation passes, send data to API
  const dataReq = { 
          fullName: fullName,
          email: email,
          phoneNumber: phoneNumber,
          password: password,    
          dob: DOBTxt,
          gender:GenderTxt,
          age: ageCal,   
          specialty: specialty,
          userProfile: userProfileTxt,      
}; 
// Here, you would replace 'apiEndpoint' with your actual API endpoint
fetch(getBaseURL+'/admin/register', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataReq),
})
 
.then(response => response.json())
.then(data => {
  if( data.status == 'success'){
    toast.success("Admin profile update successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });  
    localStorage.setItem('UserProfileAdmin', JSON.stringify(data));
 
    setShowLoader(false) 
    setError('')
    handleCancel()
  }
  if( data.status == 'error'){ 
      toast.error(data.message, {
         position: toast.POSITION.TOP_RIGHT,
        });   
        setError('')
        setShowLoader(false)
  }
    // Optionally, you can redirect the user or show a success message
})
.catch((error) => {
    console.error('Error:', error);
    // Optionally, you can display an error message to the user
});
};

 

const handleCancel = () => {
  let path = `/admin/adminusers`;
  navigate(path); 
  setIsEditing(false);
  setError('')
};

const handleFullNameChange = (event) => {
  // Remove non-text characters from the input value
  const value = event.target.value.replace(/[^a-zA-Z\s]/g, '');
  setFullName(value);   
};
const handlePhoneNumberChange = (event) => {
  // Remove non-numeric characters from the input value
  const value = event.target.value.replace(/\D/g, '');
  setPhoneNumber(value);
};

  return (
    <div>
    <div className='container-fluid p-0 adminDashboardMain'>
      <SideBarMenu/>
    
      <div className='container-fluid p-0 contentBody'>
           <Header />
           <div className="innerBodyBox bgInnerDiv">

            <div className="contentInnerBody">
        <div className=' p-0    '>  
        <div className='loginBoxDiv ForgotPassswordMain UserProfileMain whiteBgColor centerDivAlign addAdminUser'> 
        <h2>Add Admin Details </h2>  
       <form onSubmit={handleSubmitProfile}> 
       <fieldset className='editUserProfile'  id="sign_up" >
          <div className='inputDivBox' >
            <label>Full Name:</label>
              <div className={"inputTxtBox registerInputDiv   "}>
                    <img src={Icons[0].userImg} className="logoImg" alt="" />
                    <input type="text" value={fullName} placeholder="Full Name" onChange={handleFullNameChange} maxLength="100" />
            </div>
            {error.fullName && <span  className="errorTxt" >{error.fullName}</span>}
          </div>
          <div className='inputDivBox' >
            <label>Email:</label>
             <div className={"inputTxtBox registerInputDiv  "}>
                    <img src={Icons[0].mailImg} className="logoImg" alt="" />
                    <input type="text" value={email} placeholder="Email id"  onChange={handleEmailChange}    /> 
                  </div>
                  {error.email && <span  className="errorTxt" >{error.email}</span>}
          </div>
          <div className='inputGroupDiv'> 
          <label>Set Password:</label> 
                   <div className="inputTxtBox registerInputDiv">
                            <img src={Icons[0].lockedImg} className="logoImg" alt="" />
                              <input type={passwordType}  value={password} placeholder="Set Password" autocomplete="new-password" onChange={handlePasswordChange}/>   
                              <label className='eyePwdIcon' onClick={handleClickPassword}>  
                                <img src={passwordType === "password" ? ( Icons[0].closeEye  ) : ( Icons[0].opneEye  )} className="logoImg" alt="" />
                              </label>                
                    </div>
              {error.password && <span  className="errorTxt" >{error.password}</span>}
           </div>
           <div className='inputDivBox' >
            <label>Profile Status:</label> 
            <div className={"inputTxtBox  registerInputDiv   " + (isEditing ? '' : 'disabled')}>
            <img src={Icons[0].admin_adminIcon} className="iconInput" alt="" /> 
              <input type="text"  value={userProfileTxt} placeholder="Status"  onChange={(e) => setUserProfileTxt(e.target.value)}  disabled={isEditing ? '' : 'true'} /> 
              
            </div>
            {error.userProfileTxt && <span  className="errorTxt" >{error.userProfileTxt}</span>}
          </div>
          <div className='inputDivBox' >
            <label>Phone Number:</label> 
            <div className={"registerInputDiv "+ (isEditing ? '' : 'disabled')}>
                    <PhoneInput
                      autocomplete="off" 
                      defaultCountry="US"
                      country={"us"}
                     id="phone"
                     placeholder="Enter phone number"
                     value={phoneNumber}
                     onChange={setPhoneNumber} 
                     international={false} 
                     inputProps={{
                       name: 'phone',
                       required: true,
                       autoFocus: true, 
                       autoComplete: 'off',
                     }}  
                     disabled={isEditing ? '' : 'true'}
                    />
                  </div> 
                  {error.phoneNumber && <span  className="errorTxt" >{error.phoneNumber}</span>}
          </div>
          <div className='inputDivBox' >
            <label>Date of Birth:</label> 
            <div className={"inputTxtBox  registerInputDiv " + (isEditing ? '' : 'disabled')}>
                        <img src={Icons[0].dateIcon} className="logoImg" alt="" />
                        <input type="text"    value={DOBTxt} placeholder="Date of Birth (YYYY-MM-DD)" readOnly /> 
                        <button className={"toggleCalendarBtn " + (isEditing ? '' : 'd-none')}  disabled={isEditing ? '' : 'true'}  onClick={toggleCalendar}> <img src={Icons[0].calendarIcon} className="logoImg" alt="" /></button>
                          {showCalendar && (
                              <Calendar 
                                className= 'inputCaledar' 
                                onChange={onChangeCalendar}
                                value={DOBTxt}
                                maxDate={new Date()}  
                              />
                          )}
                   </div>
                  {error.DOBTxt && <span  className="errorTxt" >{error.DOBTxt}</span>} 
          </div>
          <div className='inputDivBox' >
            <label>Specialty:</label> 
            <div className={"inputTxtBox  registerInputDiv " + (isEditing ? '' : 'disabled')}>
                    <img src={Icons[0].InsuranceIDIcon} className="logoImg" alt="" />
                     <input type="text" value={specialty} placeholder="Specialty"  disabled={isEditing ? '' : 'true'} onChange={(e) => setSpecialty(e.target.value)}   maxLength="100" /> 
                  </div>
                  {error.specialty && <span  className="errorTxt" >{error.specialty}</span>} 
          </div>
          <div className='inputDivBox' >
            <label>Gender:</label>
            <div className={"inputTxtBox  registerInputDiv " + (isEditing ? '' : 'disabled')}>
                        <img src={Icons[0].GenderImg} className="logoImg" alt="" />
                        <input type="text" value={GenderTxt} placeholder="Sex" onChange={handleGenderChange} maxLength="1" disabled={isEditing ? '' : 'true'}
                        onKeyUp={(e) => { if (e.key === 'Backspace') { setGenderTxt('');  } }}/> 
            </div>
            {error.GenderTxt && <span  className="errorTxt" >{error.GenderTxt}</span>} 
          </div>
        
       
         
          </fieldset>
{
  isEditing ? (<>
  <div className={"saveOptDivBox "}>
          {ShowLoader ? (<>
                      <button  className="   " > Loading
                      <div className="spinner-border text-light  " role="status">
                       <span className="visually-hidden">Loading...</span>
                    </div> 
                    </button>
                    </>):(<>
                      <button onClick={handleSubmitProfile} type="submit" >Save</button>
                    </>)} 
                  <button type="button" onClick={handleCancel}>Cancel</button>
            </div>
  </>) : ('')
}
          
          
        </form>
     

                 
      </div>
         </div>
    </div> 
    </div> 
    </div> 
    </div> 
    </div> 
  );
}

export default AddAdminUser;
