import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify"; 
import Header from './header'; 
import axios from 'axios';
import { Icons } from "../../assest/icons";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment'; 
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import SideBarMenu from './sideBarMenu';
import {getBaseURL} from '../baseUrl';

 

function AdminUserinfo() {
  const locationi = useLocation(); 
  const [userid, setuserid] = useState(locationi.state);
 
  const [userProfileData, setUserProfileData] = useState([]); 
 
  const [fullName, setFullName] = useState(userProfileData.fullName);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(userProfileData.phoneNumber);
  const [userProfile, setUserProfile] = useState('');  
  const [specialty, setSpecialty] = useState('');
  const [selectedToggle, setSelectedToggle] = useState(true);
  const [DOBTxt, setDOBTxt] = useState(userProfileData.dob); 
  const [InsuranceIdTxt, setInsuranceIdTxt] = useState(userProfileData.insurance);
  const [GenderTxt, setGenderTxt] = useState(userProfileData.gender);
  const [originalProfile, setOriginalProfile] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');   
  var years = moment().diff(userProfileData.dob);
  const ageCal = moment().diff(DOBTxt, 'years');  

  const [AgeTxt, setAgeTxt] = useState(ageCal);  
  const [WeightTxt, setWeightTxt] = useState(userProfileData.weight);
  const [HeightTxt, setHeightTxt] = useState(userProfileData.height);
  const [ShowLoader, setShowLoader] = useState(false); 

  const [UserId, setUserId] = useState(userProfileData.user_id);  
  const [showCalendar, setShowCalendar] = useState(false);  
  const [isValid, setIsValid] = useState(false);
 


  useEffect(() => {
    const fetchRecords = async () => {
      try {
        // Make a GET request to fetch records from the API
        const response = await axios.post(getBaseURL+'/get_data_by_id/'+userid, {
          headers: {
            'Content-Type': 'application/json, text/html', // Specify the Content-Type header
          },
        });
        setUserProfileData(response.data); // Assuming response.data contains the array of records 
        setFullName(response.data.fullName)
        setEmail(response.data.email)
        setDOBTxt(response.data.dob)
        setPhoneNumber(response.data.phoneNumber)
        setUserProfile(response.data.userProfile)
        setSpecialty(response.data.specialty)
        setGenderTxt(response.data.gender)
     

      } catch (error) {
        console.error('Error fetching records:', error);
        // Handle error, show an error message, etc.
      }
    };
    fetchRecords();
  }, []); 

 
   
  const onChangeCalendar = (date) => {     
    setDOBTxt(formatDate(date));
    setShowCalendar(false); 
};
  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
};
const formatDate = (date) => { 
  return date ? moment(date).format("YYYY-MM-DD") : '';
};
  const handleChangeCancel = () => {
    setIsEditing(!isEditing);
};
const handleGenderChange = (event) => {
  let { value } = event.target; 
      // Allow delete action
      if (value === '') {
        setGenderTxt(''); 
    } 
    // Convert input to uppercase for comparison
    value = value.toUpperCase();
  // Allow only 'M' or 'F' characters
  if ( value === 'M' || value === 'F') { 
      setGenderTxt(value === 'M' ? 'Male' :  'Female');
  }
};
 
const handleChange = (e) => {
  const { name, value } = e.target; 
 
};

const handleSubmitProfile = (event) => { 

 event.preventDefault(); 
  // Simple form validation
  let formErrors = {}; 
  if (!phoneNumber.trim()) {
    formErrors.phoneNumber = 'Please enter your phone number';
  }
  if (!DOBTxt.length > 0) {
    formErrors.DOBTxt = 'Please select a date';
  }  
  if (!GenderTxt.trim()) {
      formErrors.GenderTxt = 'Please enter your sex';
  }  
  if (!specialty.trim()) {
      formErrors.specialty = 'Please enter your weight';
  }
  if (!userProfile.trim()) {
      formErrors.userProfile = 'Please enter your height';
  } 
  if (Object.keys(formErrors).length > 0) {
      setError(formErrors);
      return;
  }
  setShowLoader(true)
  // If validation passes, send data to API
  const dataReq = { 
    phoneNumber: phoneNumber, 
    dob: DOBTxt,
    gender:GenderTxt, 
    userProfile: userProfile,
    specialty: specialty, 
    user_id: userid,
    email:email,
    fullName:fullName, 
    age:ageCal,
}; 
// Here, you would replace 'apiEndpoint' with your actual API endpoint
fetch(getBaseURL+'/admin/update_user', {
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataReq),
})
 
.then(response => response.json())
.then(data => {
  if( data.status == 'success'){
    toast.success("User profile update successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });  
    setShowLoader(false) 
    setError('')
    handleCancel()
  }
  if( data.status == 'error'){ 
      toast.error(data.message, {
         position: toast.POSITION.TOP_RIGHT,
        });   
        setError('')
        setShowLoader(false)
  }
    // Optionally, you can redirect the user or show a success message
})
.catch((error) => {
    console.error('Error:', error);
    // Optionally, you can display an error message to the user
});
};



const handleCancel = () => { 
  setIsEditing(false);
  setError('')
};

const handleFullNameChange = (event) => {
  // Remove non-text characters from the input value
  const value = event.target.value.replace(/[^a-zA-Z\s]/g, '');
  setFullName(value);   
};
const handlePhoneNumberChange = (event) => {
  // Remove non-numeric characters from the input value
  const value = event.target.value.replace(/\D/g, '');
  setPhoneNumber(value);
};

  return (
    <div>
    <div className='container-fluid p-0 adminDashboardMain'>
      <SideBarMenu/>
    
      <div className='container-fluid p-0 contentBody'>
           <Header />
           <div className="innerBodyBox bgInnerDiv">

            <div className="contentInnerBody">
        <div className=' p-0    '> 
        <div className='loginBoxDiv ForgotPassswordMain UserProfileMain whiteBgColor centerDivAlign addAdminUser'> 
        <h2>Admin Details    <button className={'editIcon ' + (isEditing ?  'btnactive' : '') } onClick={() => setIsEditing(true)}>Edit
           <img src={isEditing ? Icons[0].editRed : Icons[0].editIcon } className="logoImg" alt="" /> 
           </button>
        </h2>  
       <form onSubmit={handleSubmitProfile}> 
       <fieldset className='editUserProfile'  id="sign_up" >
          <div className='inputDivBox' >
            <label>Full Name:</label>
              <div className={"inputTxtBox registerInputDiv  disabled"}>
                    <img src={Icons[0].userImg} className="logoImg" alt="" />
                    <input type="text" value={fullName} placeholder="Full Name" onChange={handleFullNameChange} readOnly />
            </div>
          </div>
          <div className='inputDivBox' >
            <label>Email:</label>
             <div className={"inputTxtBox registerInputDiv disabled"}>
                    <img src={Icons[0].mailImg} className="logoImg" alt="" />
                    <input type="text" value={email} placeholder="Email id"  onChange={handleChange}  readOnly /> 
                  </div>
          </div>
          <div className='inputDivBox' >
            <label>Phone Number:</label> 
            <div className={"registerInputDiv "+ (isEditing ? '' : 'disabled')}>
                    <PhoneInput
                      autocomplete="off" 
                      defaultCountry="US"
                      country={"us"}
                     id="phone"
                     placeholder="Enter phone number"
                     value={phoneNumber}
                     onChange={setPhoneNumber} 
                     international={false} 
                     onBlur={() => setIsValid(!!phoneNumber.trim())}
                     inputProps={{
                       name: 'phone',
                       required: true,
                       autoFocus: true, 
                       autoComplete: 'off',
                     }}  
                     disabled={isEditing ? '' : 'true'}
                    />
                  </div> 
                  {error.phoneNumber && <span  className="errorTxt" >{error.phoneNumber}</span>}
          </div>
          <div className='inputDivBox' >
            <label>Date of Birth:</label> 
            <div className={"inputTxtBox  registerInputDiv " + (isEditing ? '' : 'disabled')}>
                        <img src={Icons[0].dateIcon} className="logoImg" alt="" />
                        <input type="text"    value={DOBTxt} placeholder="Date of Birth (YYYY-MM-DD)" readOnly /> 
                        <button className={"toggleCalendarBtn " + (isEditing ? '' : 'd-none')}  disabled={isEditing ? '' : 'true'}  onClick={toggleCalendar}> <img src={Icons[0].calendarIcon} className="logoImg" alt="" /></button>
                          {showCalendar && (
                              <Calendar 
                                className= 'inputCaledar' 
                                onChange={onChangeCalendar}
                                value={DOBTxt}
                                maxDate={new Date()}  
                              />
                          )} 
                  </div>
                  {error.DOBTxt && <span  className="errorTxt" >{error.DOBTxt}</span>} 
          </div>
          <div className='inputDivBox' >
            <label>Specialty:</label> 
            <div className={"inputTxtBox  registerInputDiv " + (isEditing ? '' : 'disabled')}>
                    <img src={Icons[0].InsuranceIDIcon} className="logoImg" alt="" /> 
                    <input type="text" value={specialty} placeholder="Insurance ID"  disabled={isEditing ? '' : 'true'} onChange={(e) => setSpecialty(e.target.value)}  /> 
                  </div>
                  {error.specialty && <span  className="errorTxt" >{error.specialty}</span>} 
          </div>
          <div className='inputDivBox' >
            <label>Gender:</label>
            <div className={"inputTxtBox  registerInputDiv " + (isEditing ? '' : 'disabled')}>
                        <img src={Icons[0].GenderImg} className="logoImg" alt="" />
                        <input type="text" value={GenderTxt} placeholder="Sex" onChange={handleGenderChange} maxLength="1"     disabled={isEditing ? '' : 'true'}
                        onKeyUp={(e) => { if (e.key === 'Backspace') { setGenderTxt('');  } }}/> 
            </div>
            {error.GenderTxt && <span  className="errorTxt" >{error.GenderTxt}</span>} 
          </div>
          <div className='inputDivBox' >
            <label>Profile Status:</label> 
            <div className={"inputTxtBox  registerInputDiv   " + (isEditing ? '' : 'disabled')}>
            <img src={Icons[0].admin_adminIcon} className="iconInput" alt="" /> 
              <input type="text"  value={userProfile}  onChange={(e) => setUserProfile(e.target.value)}  disabled={isEditing ? '' : 'true'} /> 
              
            </div>
            {error.userProfile && <span  className="errorTxt" >{error.userProfile}</span>}
          </div>
       
         
          </fieldset>
{
  isEditing ? (<>
  <div className={"saveOptDivBox "}>
          {ShowLoader ? (<>
                      <button  className="   " > Loading
                      <div className="spinner-border text-light  " role="status">
                       <span className="visually-hidden">Loading...</span>
                    </div> 
                    </button>
                    </>):(<>
                      <button onClick={handleSubmitProfile} type="submit" >Save</button>
                    </>)} 
                  <button type="button" onClick={handleCancel}>Cancel</button>
            </div>
  </>) : ('')
}        
          
        </form>
                 
      </div>
         </div>
    </div> 
    </div> 
    </div>
         </div>
    </div>  
  );
}

export default AdminUserinfo;
