import React, { useState, useEffect  } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Icons } from "../../assest/icons";  

function Header() { 
  const usernameTxt =  JSON.parse(localStorage.getItem('UserProfileAdmin'));  
  const [UserName, setUserName] = useState(usernameTxt.fullName); 
  let navigate = useNavigate(); 
  const handleSettings = () => { 
    navigate("/admin/adminusersinfo", { state: usernameTxt.user_id });  
  };    
  const handleLogout = () => { 
    navigate("/");
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };
  const handleforgotpwd = () => { 
    navigate("/admin/changepassword");
  }
  const handleHomeScreen = () => { 
    navigate("/dashboard");
  }
  
  return ( 
     <div className="containerDiv whiteBg headerDivBox adminHeader" >  
         <ul className="rightDiv d-inline-block sidenavList">  
          <li className="listOpt accordion ">
                  <span className="profileImg">
                      <b> 
                      {UserName.split(' ').map(word => word.charAt(0).toUpperCase()).join('') }
                            </b>  
                  </span>  
                  <span className="ddownDiv form-group  dropdown-toggle toggle-select-result"
                    role="button"   data-bs-toggle="dropdown" > </span> 
                    <div
                id="regularCollapseDdown"
                className="accordion-collapse collapse dropdown-menu  "
              >
                <div className="accordion-body p-0">
                  <ul className="accordion mt-0" id="myAccordion1">
                      <li> <b><img src={Icons[0].userImg} className="logoImg" alt="" /></b> <a onClick={() => handleSettings()}>My Profile</a> </li>
                      <li> <b> <img src={Icons[0].lockedImg} alt="" /></b> <a onClick={() => handleforgotpwd()}> Change Password</a> </li>
                      
                  </ul>
                
                </div>
              </div>
              </li>
          </ul>
        </div>  
  )
}

export default Header
