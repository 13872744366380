import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify"; 
import moment from 'moment'; 
import { getBaseURL } from '../baseUrl';

const PageOne = ({ onButtonClick}) => {

  const location = useLocation(); 
  const [data, setdata] = useState(location.state);
  const [ShowLoader, setShowLoader] = useState(false);

  const userProfileData =  JSON.parse(localStorage.getItem('UserProfile'))

  const [UserId, setUserId] = useState(userProfileData.user_id);
  const [GenderTxt, setGenderTxt] = useState(userProfileData.gender);
  var years = moment().diff(userProfileData.dob);
  const [AgeTxt, setAgeTxt] = useState(years);  
  const [WeightTxt, setWeightTxt] = useState('');
  const [HeightTxt, setHeightTxt] = useState('');
  
  const [CheckWeightTxt, setCheckWeightTxt] = useState('');
  const [CheckHeightTxt, setCheckHeightTxt] = useState('');

  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);

  const [unit, setUnit] = useState('cm'); // Initial unit is 'cm' 
  const [weightUnit, setWeightUnit] = useState('lbs');

   // Function to convert kg to lbs
   const convertKgToLbs = (kg) => (parseFloat(kg) * 2.20462).toFixed(0);

   // Function to convert feet to cm
   const convertFeetToCm = (feet) => (parseFloat(feet) * 30.48).toFixed(0);
 

  useEffect(() => {
    const ageCal = moment().diff(userProfileData.dob, 'years'); 
    setAgeTxt(userProfileData.dob ? ageCal : '');    
    setHeightTxt(userProfileData.height ? userProfileData.height : '');  
    setWeightTxt(userProfileData.weight ? userProfileData.weight : '');  
    setCheckWeightTxt(userProfileData.weight ? userProfileData.weight : '');  
    setCheckHeightTxt(userProfileData.height ? userProfileData.height : '');  
    localStorage.setItem('UserId', JSON.stringify(UserId));
  }, [UserId]);
  const handleSubmit = (event) => {

    event.preventDefault();

    // Simple form validation
    let formErrors = {};
     
    if (!WeightTxt.trim()) {
        formErrors.WeightTxt = 'Please enter your weight';
    }
    if (!HeightTxt.trim()) {
        formErrors.HeightTxt = 'Please enter your height';
    } 

    if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        return;
    }
    setShowLoader(true)
    // Check if weight and height have changed
    const hasChanged = WeightTxt !== CheckWeightTxt || HeightTxt !== CheckHeightTxt;

    if (!hasChanged) {
      // Redirect to another page if no changes
      setShowLoader(false)
      onButtonClick("pagetwo")
      return;
    }

    let finalWeight = WeightTxt;
    let finalHeight = HeightTxt;

    if (weightUnit === 'kg') {
      finalWeight = convertKgToLbs(WeightTxt); // Convert kg to lbs
    }

    if (unit === 'feet') {
      finalHeight = convertFeetToCm(HeightTxt); // Convert feet to cm
    }

    // If validation passes, send data to API
    const data = { 
      age: AgeTxt,
      height: finalHeight,
      weight: finalWeight,
      gender:GenderTxt,    
      user_id: UserId,
  };
  
  // Here, you would replace 'apiEndpoint' with your actual API endpoint
  fetch(getBaseURL+'/update_user_info', {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
  })
   
  .then(response => response.json())
  .then(data => {
    if( data.status == 'success'){
      toast.success("Data updated successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      }); 
      localStorage.setItem('UserProfile', JSON.stringify(data));
         setShowLoader(false)
      onButtonClick("pagetwo")
    }
 
      // Optionally, you can redirect the user or show a success message
  })
  .catch((error) => {
      //console.error('Error:', error);
      // Optionally, you can display an error message to the user
  });
};



 // Handle unit change (cm or feet)
 const handleUnitChange = (event) => {
  const newUnit = event.target.value;
  setUnit(newUnit);

  // Convert height if a value exists
  if (HeightTxt) {
    setHeightTxt(convertHeight(HeightTxt, newUnit));
  }
};

  // Convert the value based on the selected unit
  const convertHeight = (value, toUnit) => {
    if (toUnit === 'feet') {
      return (parseFloat(value) * 0.0328084).toFixed(2); // Convert cm to feet
    } else if (toUnit === 'cm') {
      return (parseFloat(value) * 30.48).toFixed(0); // Convert feet to cm
    }
    return value;
  };
   // Handle height input change
   const handleHeightChange = (event) => {
    const value = event.target.value;
    // // Remove any non-digit characters
    // const numericValue = value.replace(/\D/g, '');
    // // Limit to 4 digits
    // if (numericValue.length <= 4) {
    //   setHeightTxt(numericValue);
    // }
    if (unit === 'cm') {
      // Allow only whole numbers for cm
      const wholeNumber = value.match(/^\d+$/);
      if (wholeNumber) {
        setHeightTxt(wholeNumber[0]);
      }
    } else {
      // Remove any non-digit characters for feet
      const numericValue = value.replace(/\D/g, '');
      if (numericValue.length <= 4) {
        setHeightTxt(numericValue);
      }
    }
  };

  // Function to convert the weight based on the selected unit
  const convertWeight = (value, toUnit) => {
    if (toUnit === 'kg') {
      return (parseFloat(value) / 2.20462).toFixed(2); // Convert lbs to kg
    } else if (toUnit === 'lbs') {
      return (parseFloat(value) * 2.20462).toFixed(0); // Convert kg to lbs
    }
    return value;
  };
 // Handle unit change (lbs or kg)
 const handleWeightUnitChange = (event) => {
  const newUnit = event.target.value;
  setWeightUnit(newUnit);

  // Convert weight if a value exists
  if (WeightTxt) {
    setWeightTxt(convertWeight(WeightTxt, newUnit));
  }
};

// Handle weight input change
const handleWeightChange = (event) => {
  const value = event.target.value;

  // Allow only numeric values and up to 2 decimal places
  const numericValue = value.match(/^\d*\.?\d{0,2}$/);
  if (numericValue) {
    setWeightTxt(numericValue[0]);
  }
};


  return (
    <main
      className="pt5 black-80 center stepFirstForm"
      style={{ maxWidth: "50%",   margin: "20px auto" }}
    >
       <form onSubmit={handleSubmit} className="measure w-100">
        <h2>Health Stats</h2> 
        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
     
          <div className="mt3 inputDiv disabledTxtField">
            <label className='labelTxt'>Age</label>
            <input type="text" value={AgeTxt}  onChange={(e) => setAgeTxt(e.target.value)} readOnly   />  
            <label>Years</label>  
         </div> 
          <div className="mt3 inputDiv">
            <label className='labelTxt'>Height</label>
            <input type="text" 
              onChange={handleHeightChange}
              placeholder={`Enter value in ${unit}`}
              value={HeightTxt} 
              // onChange={(e) => {
              //   const value = e.target.value;
              //   // Remove any non-digit characters
              //   const numericValue = value.replace(/\D/g, '');
              //   // Limit to 4 digits
              //   if (numericValue.length <= 3) {
              //     setHeightTxt(numericValue);
              //   }
              // }}
              //  onChange={(e) => setHeightTxt(e.target.value)}
                /> 
                <span className='radioConvertDiv'>
                    <label>
                        <input
                          type="radio"
                          value="cm"
                          checked={unit === 'cm'}
                          onChange={handleUnitChange}
                        />
                        Cm
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="feet"
                          checked={unit === 'feet'}
                          onChange={handleUnitChange}
                        />
                        Feet
                      </label>           
              </span>                
                  </div>
                  {errors.HeightTxt && <span  className="errorTxt" >{errors.HeightTxt}</span>}
          <div className="mt3 inputDiv">
            <label className='labelTxt'>Weight</label>
            <input type="text"  
            //  onChange={(e) => setWeightTxt(e.target.value)} 
            value={WeightTxt}
            onChange={handleWeightChange}
            placeholder={`Enter weight in ${weightUnit}`}
             />  
             <span className='radioConvertDiv'>
               <label>
                  <input
                    type="radio"
                    value="lbs"
                    checked={weightUnit === 'lbs'}
                    onChange={handleWeightUnitChange}
                  />
                  Lbs
                </label>
                <label>
                  <input
                    type="radio"
                    value="kg"
                    checked={weightUnit === 'kg'}
                    onChange={handleWeightUnitChange}
                  />
                  Kg
                </label>
                </span>     
                  </div>
                  {errors.WeightTxt && <span  className="errorTxt" >{errors.WeightTxt}</span>}
        
        </fieldset>
        <div>
        {ShowLoader ? (<>
                      <button  className="signInBtn mb-0" > Loading
                      <div className="spinner-border text-light  " role="status">
                       <span className="visually-hidden">Loading...</span>
                    </div> 
                    </button>
                    </>):(<>
                      <button className="signInBtn mb-0" type="submit" >Next</button>
                    </>)}

         
        </div>
      </form>
    </main>
  );
};

export default PageOne;
