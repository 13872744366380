import  React, { useState, useRef, useEffect  } from "react"; 
import { Link, useNavigate } from "react-router-dom";
import { Icons } from "../assest/icons";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Moment from 'moment';
import { getBaseURL } from './baseUrl';

import { ToastContainer, toast } from "react-toastify"; 
import LeftSideLogin from "./leftsidelogin";
 
 
function ForgotPassword() {   

  const [ShowLoader, setShowLoader] = useState(false); 
  const [UserId, setUserId] = useState('');
  const [firstStep, setFirstStep] = useState(true);
  const [twoStep, setTwoStep] = useState(false); 
  const [finalStep, setFinalStep] = useState(false);
 
  const [email, setEmail] = useState('');  

  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false); 
   
  
const handleEmailChange = (event) => {
  const { value } = event.target;
  setEmail(value);
  setErrors('');
};
 
 
  const [errorMessage, setErrorMessage] = useState('');

 
  const handleResetFormStart= () => { 
    setEmail(''); 
    setErrors('');
  }; 
  const handleClickFirst = () => {
    setFirstStep(false);
    setTwoStep(false);  
  };  
 
 
 
  const handleSubmit = (event) => {

        event.preventDefault();
    
        // Simple form validation
        let formErrors = {}; 
        if (!email.trim()) {
            formErrors.email = 'Please enter your email';
        }
        if (!isValidEmail(email)) {
          formErrors.email = 'Please enter a valid email address'; 
        } 
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }
        setShowLoader(true)
        // If validation passes, send data to API
        const data = {  
          email: email, 
      };
    
      // Here, you would replace 'apiEndpoint' with your actual API endpoint
      fetch(getBaseURL+'/forgot_password', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
     
      .then(response => response.json())
      .then(data => {
        if( data.status == 'success'){
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });  
          setUserId(data.user_id) 
          setShowLoader(false)       
          setFirstStep(false); 
          setFinalStep(true);      
          handleResetFormStart()
          handleClickFirst()
        }
        if( data.status == 'error'){
        //  console.log('Success:', data.message);
            toast.error(data.message, {
               position: toast.POSITION.TOP_RIGHT,
              });
              setShowLoader(false)
        }
          // Optionally, you can redirect the user or show a success message
      }) 
      .catch((err) => { 
        if (err.response && err.response.status === 400) { 
          setErrorMessage('Bad Request: Please check your input data.');
        } else {
          // Handle other types of errors
          setErrorMessage('An error occurred. Please try again later.');
        }
          console.log('Error:', err);
          // Optionally, you can display an error message to the user
      });
    };
    const isValidEmail = (email) => {
      // Regular expression to validate email format
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    };
 
    
 
  return (
    <div>
      <div className='container-fluid p-0 bgImageUrl'>
       
        <div className='container p-0 contentBody loginScreen'>

         <LeftSideLogin/>
          <div className="col-lg-5 d-inline-block position-relative">
            <div className="loginBoxDiv">
            <div className={"singUpFirstStep "+(firstStep ? "":"d-none")}>
                <h3 className="titleDespTxt">Forgot Password?</h3>
                <p>Enter your email address below and we'll send you a link to reset your password.</p>
                <form onSubmit={handleSubmit} autoComplete="off">
               
                 
                  <div className='inputGroupDiv mt-5'> 
                      <label><b>Enter your email address below</b></label>
                      <div className="inputTxtBox registerInputDiv mt-2">
                        <img src={Icons[0].mailImg} className="logoImg" alt="" />
                          <input type="text" value={email} placeholder="Email id" autoComplete="off" onChange={handleEmailChange}/>  
                      
                      </div>
                      {errors.email && <span  className="errorTxt" >{errors.email}</span>}
                </div>
                            
                 
                  {ShowLoader ? (<>
                      <button  className="signInBtn" > Loading
                      <div className="spinner-border text-light  " role="status">
                       <span className="visually-hidden">Loading...</span>
                    </div> 
                    </button>
                    </>):(<>
                      <button className="signInBtn " type="submit" >Send password reset link</button>
                    </>)}
                  
                  <div className="signupDiv">
                   <Link to="/">Back to login</Link>
                  </div>
                </form>
             </div> 

              
              <div className={"singUpFinalStep  "+(finalStep ? "":"d-none")}>
                    <span className="emailVerificationDiv">
                    <img src={Icons[0].checkedIcon} className="logoImg" alt="" />
                    <h3>Check Your Email</h3>
                    <p>We sent a password reset link to your email address. Check your inbox for further instructions.</p>
                    <button className="signInBtn mb-0"   >Back to login</button>
                    <span style={{display:"block", marginTop:"40px"}}>Didn’t receive the email? <a class="linkBlueTxt" href="/forgotpassword">Click to here to resend.</a></span>
                    </span>
              </div>
            </div>

           
          </div>
          
        </div>
      
      </div>
    </div>
  );
}

export default ForgotPassword;