import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify"; 
import Header from './header';
import { Icons } from "../assest/icons";
import { getBaseURL } from './baseUrl';
  

function Userforgot() {
 
  const location = useLocation(); 
  const [data, setdata] = useState(location.state); 
  const [oldPasswordType, setOldPasswordType] = useState("password");
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [passwordType, setPasswordType] = useState("password");

  const userProfileData =  JSON.parse(localStorage.getItem('UserProfile'))
   
  const [ShowLoader, setShowLoader] = useState(false);

  const [UserId, setUserId] = useState(userProfileData.user_id);  
  const [CurrentPwd, setCurrentPwd] = useState('');  
  const [NewPwd, setNewPwd] = useState('');
  const [ConfirmPwd, setConfirmPwd] = useState('');

  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  useEffect(() => { 
    localStorage.setItem('UserId', JSON.stringify(UserId));
  }, [UserId]);
  const handleResetForm= () => { 
    setNewPwd(''); 
    setCurrentPwd(''); 
    setConfirmPwd(''); 
    setErrors('');
  };
  const handleClickShowPassword = (event) => { 
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const handleClickShowOldPassword = (event) => { 
    if (oldPasswordType === "password") {
      setOldPasswordType("text");
      return;
    }
    setOldPasswordType("password");
  };
  const handleClickShowNewPassword = (event) => { 
    if (newPasswordType === "password") {
      setNewPasswordType("text");
      return;
    }
    setNewPasswordType("password");
  };
  const handleOldPasswordChange = (event) => {
    const { value } = event.target;
    setCurrentPwd(value);
    setErrors('');
};

const handleNewPasswordChange = (event) => {
    const { value } = event.target;
    setNewPwd(value);
    setErrors('');
};

const handleConfirmPasswordChange = (event) => {
    const { value } = event.target;
    setConfirmPwd(value);
    setErrors('');
};
const handleSubmit = async (event) => { 
 
    event.preventDefault();

    // Simple form validation
    let formErrors = {};
    if (!CurrentPwd.trim()) {
        formErrors.CurrentPwd = 'Please enter your current password';
    }
    if (!NewPwd.trim()) {
        formErrors.NewPwd =  'Please enter your New password';
    }
    if (!ConfirmPwd.trim()) {
        formErrors.ConfirmPwd = 'Please enter your Confirmed password';
    }  
    if (!isValidNewPassword(NewPwd)) {
      formErrors.NewPwd = 'New password must be at least 8 characters long'; 
    }
    if (NewPwd !== ConfirmPwd ) {
      formErrors.ConfirmPwd = 'New password and confirm password do not match';   
    }
    if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        return;
    }
    setShowLoader(true)

    // If validation passes, send data to API
    const data = {     
      user_id: UserId, 
      current_password : CurrentPwd,
      new_password : ConfirmPwd,
  }; 

  // Here, you would replace 'apiEndpoint' with your actual API endpoint
  fetch(getBaseURL+'/change_password', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
  })
   
  .then(response => response.json())
  .then(data => {
    if( data.status == 'success'){
      toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      }); 
      setShowLoader(false)
      handleResetForm()  
    }
    if( data.status == 'error'){ 
        toast.error(data.message, {
           position: toast.POSITION.TOP_RIGHT,
          });
          setShowLoader(false)
    }
 
      // Optionally, you can redirect the user or show a success message
  })
  .catch((error) => {
      console.error('Error:', error);
      // Optionally, you can display an error message to the user
  });
};

const isValidNewPassword = (password) => {
  return password.length >= 8;
};

  return (
    <div > 
     <div className='container-fluid p-0 bgInnerDiv'>
        <Header />
        <div className='container-fluid p-0 contentBody  '>  
        <div className='loginBoxDiv ForgotPassswordMain whiteBgColor centerDivAlign'>
        <form onSubmit={handleSubmit} className="userforgotpwddiv measure w-100">
        <h2>Forgot Passsword</h2> 
        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
        <div className='inputGroupDiv'>
            <label className='labelTxt'>Current Password</label>
            <div className="inputTxtBox registerInputDiv">
               <img src={Icons[0].lockedImg} className="logoImg" alt="" />
               <input type={oldPasswordType}  value={CurrentPwd}   
               onChange={handleOldPasswordChange}
               /> 
               <label className='eyePwdIcon' onClick={handleClickShowOldPassword}>  
                 <img src={oldPasswordType === "password" ? ( Icons[0].closeEye  ) : ( Icons[0].opneEye  )} className="logoImg" alt="" />
               </label>   
          </div>
          {errors.CurrentPwd && <span  className="errorTxt" >{errors.CurrentPwd}</span>}
          </div>
          <div className='inputGroupDiv'>
            <label className='labelTxt'> New Password</label>
          <div className="inputTxtBox registerInputDiv"> 
          <img src={Icons[0].lockedImg} className="logoImg" alt="" />          
            <input type={newPasswordType}  value={NewPwd}   
            onChange={handleNewPasswordChange} /> 
            <label className='eyePwdIcon' onClick={handleClickShowNewPassword}>  
               <img src={newPasswordType === "password" ? ( Icons[0].closeEye  ) : ( Icons[0].opneEye  )} className="logoImg" alt="" />
            </label> 
          </div> 
          {errors.NewPwd && <span  className="errorTxt" >{errors.NewPwd}</span>}
          </div>
          <div className='inputGroupDiv'>
            <label className='labelTxt'>Confirm Password</label>
          <div className="inputTxtBox registerInputDiv">
            <img src={Icons[0].lockedImg} className="logoImg" alt="" />
            <input type={passwordType}  value={ConfirmPwd}    
            onChange={handleConfirmPasswordChange}/>   
            <label className='eyePwdIcon' onClick={handleClickShowPassword}>  
               <img src={passwordType === "password" ? ( Icons[0].closeEye  ) : ( Icons[0].opneEye  )} className="logoImg" alt="" />
            </label>    
          </div>
          {errors.ConfirmPwd && <span  className="errorTxt" >{errors.ConfirmPwd}</span>}
  </div>
        </fieldset>
                {ShowLoader ? (<>
                      <button  className="signInBtn" > Loading
                      <div className="spinner-border text-light  " role="status">
                       <span className="visually-hidden">Loading...</span>
                    </div> 
                    </button>
                    </>):(<>
                      <button className="signInBtn" type="submit" >Update</button>
                    </>)}

         
      </form>         
      </div>
         </div>
    </div> 
    </div> 
  );
}

export default Userforgot;
