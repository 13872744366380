import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Icons } from "../assest/icons";

function LeftSideLogin() {
  
 
  return (
    <div className="col-lg-7 d-inline-block infoDivBox">
                <div className="leftDiv d-inline-block logoDiv">
                  <Link to="/">
                    <img src={Icons[0].logoIcon} className="logoImg" alt="" />
                  </Link>
                </div>
              <h1>Modern medicine meets<br/>
                modern convenience</h1>
                <p>No commutes. No waiting rooms. AIGI Care uses virtual technology so you can consult with a 
                  healthcare professional without the hassles of a physical visit</p>

                  <b className='indexGastroTxt'>Gastroenterology powered by Artificial Intelligence</b>
                  
                  <span>
                      <h3>Great partners bring the greatest impact</h3>
                      <p>We are proud to work with some of the biggest companies to help people live longer, fuller lives.</p>

                      <ul>
                        <li><img src={Icons[0].amazonImg} /></li>
                        <li><img src={Icons[0].appleImg} /></li>
                        <li><img src={Icons[0].googleImg} /></li>
                        <li><img src={Icons[0].sumsungImg} /></li>
                      </ul>

                  </span>
          </div>
  )
}

export default LeftSideLogin
